import { useContext } from 'react';
import './UtilsRow.css';
import { MobileStateContext } from '../../../contexts/MobileContext';
import PlusButton from '../../../components/buttons/PlusButton';
import plus from '../../../images/plus.svg';
import pinkPlus from '../../../images/pink-plus.svg';
import { ReactComponent as Download } from '../../../images/download.svg';
import { ReactComponent as Copy } from '../../../images/copy.svg';
import { ReactComponent as Send } from '../../../images/send.svg';
import { ReactComponent as Close } from '../../../images/close.svg';

interface UtilsRowProps {
  openPdfMenu: () => void;
  pdfJobs: Set<string>;
  openAddJob: () => void;
  openAddSlinga: () => void;
  // confirmSendLink: () => void;
}

const UtilsRow = ({
  openPdfMenu,
  pdfJobs,
  openAddJob,
  openAddSlinga,
}: // confirmSendLink,
UtilsRowProps) => {
  const jobsMarked = 0;
  const { isMobile } = useContext(MobileStateContext);
  return (
    <div className='utilsContainer'>
      <div className='utilsRow'>
        <div className='utilsFunctions'>
          <Close />
          <p style={{ color: '#464646' }}>{`${jobsMarked} markerade`}</p>
        </div>
        <div className='utilsFunctions'>
          <div className='utilsButton'>
            <Send />
            <p>Skicka uppdrag till förare</p>
          </div>
          <div className='utilsButton'>
            <Copy />
            <p> Kopiera uppdrag</p>
          </div>
          <div className='utilsButton' onClick={openPdfMenu}>
            <Download />
            <p>Visa PDF</p>
          </div>
        </div>
      </div>

      <section id='add-job-or-slinga-button-group'>
        {!isMobile && (
          <>
            <button className='primary-button-week' onClick={openAddJob}>
              <img src={plus} />
              Uppdrag
            </button>
            <button className='secondary-button-week' onClick={openAddSlinga}>
              <img src={pinkPlus} />
              Slinga
            </button>
          </>
        )}
        {isMobile && <PlusButton openAddJob={openAddJob} openAddSlinga={openAddSlinga} />}
      </section>
    </div>
  );
};

export default UtilsRow;
