import React, { useEffect, useState } from 'react';
import {
  DaysWorkload,
  JobData,
  JobDataPartial,
  JobDoc,
  JobsState,
  SlingaData,
  SlingaDataPartial,
  SlingaDoc,
  User,
} from '../utils/types';
import { Firestore, collection, doc, onSnapshot, or, query, where } from 'firebase/firestore';
import { changeDay, isDateInRange } from '../utils/time';
import { CalendarMode, JobStatus, UserType } from '../utils/constants';
import {
  addJobOrSlingaToCorrectDays,
  getJobsEmptyWeek,
  modifyJobOrSlingaInJobs,
  removeJobOrSlingaFromJobs,
} from '../utils/jobUtils';
import { createSlingaDataPartial } from '../firebase/firestore_functions/slinga';
import {
  getAllDraftJobs,
  getDraftsWithoutDates,
  toJobDataPartial,
} from '../firebase/firestore_functions/job';
import _ from 'lodash';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isToday from 'date-fns/isToday';

interface JobsContextProviderProps {
  children: React.ReactElement;
}

const initialState: JobsState = {
  jobs: {},
  setJobs: () => undefined,
  filteredJobs: {},
  setFilteredJobs: () => undefined,
  vehicleFilter: new Set(),
  setVehicleFilter: () => undefined,
  littraFilter: new Set(),
  setLittraFilter: () => undefined,
  driverFilter: new Set(),
  setDriverFilter: () => undefined,
  clientFilter: new Set(),
  setClientFilter: () => undefined,
  loading: false,
  showOnlyJobsToHandle: false,
  setShowOnlyJobsToHandle: () => undefined,
  showOnlySlingor: false,
  setShowOnlySlingor: () => undefined,
  setListenerOnJobsCollection: () => () => undefined,
  filterJobs: () => undefined,
  getDraftsOtherWeeks: () => undefined,
  draftJobs: [],
  getDraftsWithoutDate: () => undefined,
  draftJobsWithoutDate: [],
  showDrafts: false,
  setShowDrafts: () => undefined,
  selectedJobs: '',
  setSelectedJobs: () => undefined,
};

export const JobsContext = React.createContext(initialState);

export function JobsContextProvider({ children }: JobsContextProviderProps) {
  const [jobs, setJobs] = useState<DaysWorkload>({});
  const [selectedJobs, setSelectedJobs] = useState<string>('');
  const [filteredJobs, setFilteredJobs] = useState<DaysWorkload>({});
  const [draftJobs, setDraftJobs] = useState<DaysWorkload[]>([]);
  const [draftJobsWithoutDate, setDraftJobsWithoutDate] = useState<DaysWorkload[]>([]);
  const [vehicleFilter, setVehicleFilter] = useState<Set<string>>(new Set());
  const [littraFilter, setLittraFilter] = useState<Set<string>>(new Set());
  const [driverFilter, setDriverFilter] = useState<Set<string>>(new Set());
  const [clientFilter, setClientFilter] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);
  const [showDrafts, setShowDrafts] = useState<boolean>(false);
  const [showOnlyJobsToHandle, setShowOnlyJobsToHandle] = useState<boolean>(false);
  const [showOnlySlingor, setShowOnlySlingor] = useState<boolean>(false);

  /**
   * sets listener on jobs collection for all jobs with its start date set to X days back in time and maximum at the current
   * endDate for calendar. Due to firestore query limitations its not possible to create the query that exactly matches the date interval
   * that we're intrested in. 30 days days back is chosen because Nolblad don't have jobs that are for a longer time period than 30 days.
   *
   */
  function setListenerOnJobsCollection(
    db: Firestore,
    startDate: number,
    endDate: number,
    user: User | undefined,
  ) {
    setLoading(true);

    const jobsQuery = createJobsQuery(db, startDate, endDate, user);

    const unsubscribe = onSnapshot(jobsQuery, async (snapshot) => {
      const changes = snapshot.docChanges();

      const additions: any[] = [];
      const removals: any[] = [];
      const modifications: any[] = [];

      for (const change of changes) {
        const jobStart = change.doc.data().start;
        const jobEnd = change.doc.data().end;

        switch (change.type) {
          case 'added':
            if (isDateInRange([jobStart, jobEnd], [startDate, endDate])) {
              // This part is duplicated in every case since we don't want to populate all data if it is not needed
              const jobOrSlinga = await toJobOrSlinga(change.doc.data(), change.doc.id);
              additions.push(jobOrSlinga);
            }
            break;
          case 'modified': {
            // This part is duplicated in every case since we don't want to populate all data if it is not needed
            const jobOrSlinga = await toJobOrSlinga(change.doc.data(), change.doc.id);
            modifications.push(jobOrSlinga);
            break;
          }
          case 'removed':
            // This part is duplicated in every case since we don't want to populate all data if it is not needed
            if (isDateInRange([jobStart, jobEnd], [startDate, endDate])) {
              const jobOrSlinga = await toJobOrSlinga(change.doc.data(), change.doc.id);
              removals.push(jobOrSlinga);
            }
            break;
        }
      }

      setJobs((prevJobs) => {
        const updatedJobs = _.cloneDeep(prevJobs);

        if (additions.length) {
          for (const jobOrSlinga of additions) {
            addJobOrSlingaToCorrectDays(jobOrSlinga, updatedJobs, startDate, endDate);
          }
        }

        if (modifications.length) {
          for (const jobOrSlinga of modifications) {
            modifyJobOrSlingaInJobs(jobOrSlinga, updatedJobs, startDate, endDate);
          }
        }

        if (removals.length) {
          for (const jobOrSlinga of removals) {
            removeJobOrSlingaFromJobs(jobOrSlinga, updatedJobs, startDate, endDate);
          }
        }

        return updatedJobs;
      });

      setLoading(false);
    });

    return unsubscribe;
  }

  function createJobsQuery(
    db: Firestore,
    startDate: number,
    endDate: number,
    user: User | undefined,
  ) {
    const daysBack = 50;

    let jobsQuery = query(
      collection(db, 'jobs'),
      where('start', '>=', changeDay(startDate, -daysBack)),
      where('start', '<=', endDate),
    );

    // If user is DRIVER or DRIVER_EXTENDED, only show jobs that are assigned to the driver
    if (user && [UserType.DRIVER, UserType.DRIVER_EXTENDED].includes(user.userType)) {
      jobsQuery = query(
        jobsQuery,
        or(
          where('driver.ref', '==', doc(db, 'users', user.docId)),
          where('driver', '==', doc(db, 'users', user.docId)),
        ),
      );
    }

    return jobsQuery;
  }

  function isJobIncludedInFilters(
    job: JobData | JobDataPartial | SlingaData,
    user: User | undefined,
  ) {
    const statusToHandle =
      user?.userType === UserType.DRIVER
        ? [JobStatus.NEW, JobStatus.STARTED_OVERDUE, JobStatus.STARTED]
        : [JobStatus.REPORTED, JobStatus.STARTED_OVERDUE];

    const jobExistsInDriverFilter: boolean =
      driverFilter?.size === 0 ||
      (job.driver && driverFilter?.has((job as JobData)?.driver?.docId ?? '')) ||
      !!(job.driver && driverFilter?.has((job as JobDataPartial)?.driver?.ref.id ?? ''));

    const jobExistsInClientFilter: boolean =
      clientFilter?.size === 0 ||
      (!!(job as JobData | JobDataPartial).client &&
        (clientFilter?.has((job as JobData)?.client?.docId ?? '') ||
          clientFilter?.has((job as JobDataPartial)?.client?.ref.id ?? '')));

    const jobExistsInVehicleFilter: boolean =
      vehicleFilter?.size === 0 ||
      (job.vehicle && vehicleFilter?.has((job as JobData)?.vehicle?.docId ?? '')) ||
      !!(job.driver && vehicleFilter?.has((job as JobDataPartial)?.vehicle?.ref.id ?? ''));

    const jobExistsInLittraFilter: boolean =
      littraFilter?.size === 0 ||
      ((job as JobDataPartial).littra?.name &&
        /* Jobs are currently in JobDataPartial form where littra name is found in job.littra.name*/
        littraFilter.has((job as JobDataPartial).littra!.ref.id)) ||
      /* In the case jobs are in JobData form, littra is found in job.littra.projectNum. */
      !!((job as JobData).littra?.projectNum && littraFilter.has((job as JobData).littra!.docId));

    return (
      jobExistsInDriverFilter &&
      jobExistsInClientFilter &&
      jobExistsInVehicleFilter &&
      jobExistsInLittraFilter &&
      (!showOnlyJobsToHandle || statusToHandle.includes(job.status)) &&
      (!showOnlySlingor || (job as SlingaData).sNumber !== undefined)
    );
  }

  /**
   * Adds the job to the _jobs object
   */
  function addJobToFilteredJobs(_jobs: DaysWorkload, day: number, job: JobDataPartial) {
    // Check if day is already present in _jobs
    if (_jobs[day]) {
      // Sort jobs on the 'vehicle' property before pushing the new job
      _jobs[day].push(job);
    } else {
      _jobs[day] = [job];
    }
  }

  /**
   * Filters jobs according to filters and updates filteredJobs
   */
  function filterJobs(
    calendarMode: CalendarMode,
    startDate: number,
    do30Days: boolean,
    NUM_OF_DAYS_LOOK_AHEAD: number,
    user: User | undefined,
  ) {
    let _jobs: DaysWorkload = {};

    // to not have empty week
    if (calendarMode === CalendarMode.WEEK) {
      _jobs = getJobsEmptyWeek(startDate, do30Days, NUM_OF_DAYS_LOOK_AHEAD);
    }

    for (const day of Object.entries(jobs)) {
      for (const job of day[1]) {
        // If we are not filtering on slingor, then go ahead and check if job is included in filters
        if (job.orderNum !== undefined) {
          // If job is included in filters, then add job to _jobs
          if (isJobIncludedInFilters(job as JobData, user)) {
            addJobToFilteredJobs(_jobs, parseInt(day[0]), job);
          }
        } else if (job.sNumber !== undefined) {
          if (isJobIncludedInFilters(job as SlingaData, user)) {
            // If slinga is included in filters, then add job to _jobs
            addJobToFilteredJobs(_jobs, parseInt(day[0]), job);
          }
        }
      }
      // Sort jobs on the 'vehicle id' property
      _jobs[parseInt(day[0])].sort((a, b) =>
        a.vehicle && b.vehicle ? (a.vehicle.name > b.vehicle.name ? 1 : -1) : 0,
      );
    }

    setFilteredJobs(_jobs);
  }

  async function getDraftsOtherWeeks(
    user: User | undefined,
    calendarMode: CalendarMode,
    startDate: number,
  ) {
    if (user && user.userType === UserType.ADMIN) {
      const today = new Date(startDate);
      const jobs = await getAllDraftJobs();
      switch (calendarMode) {
        case CalendarMode.MONTH: {
          const firstDateOfMonth = new Date(format(today, 'yyyy-MM-01'));
          const endDateOfMonth = new Date(format(lastDayOfMonth(today), 'yyyy-MM-dd'));

          const filteredDraftJobs = jobs.data.filter((job: JobData) => {
            const jobStartDate = job.start ? new Date(job.start) : null;
            const jobEndDate = job.end ? new Date(job.end) : null;

            return (
              (jobEndDate && jobEndDate.getTime() < firstDateOfMonth.getTime()) ||
              (jobStartDate && jobStartDate.getTime() > endDateOfMonth.getTime())
            );
          });
          setDraftJobs(filteredDraftJobs);
          break;
        }

        case CalendarMode.WEEK: {
          const firstDateOfWeek = startOfWeek(today);
          const lastDateOfWeek = endOfWeek(today);

          const formattedFirstDateOfWeek = new Date(format(firstDateOfWeek, 'yyyy-MM-dd'));
          const formattedLastDateOfWeek = new Date(format(lastDateOfWeek, 'yyyy-MM-dd'));

          const filteredDraftJobs = jobs.data.filter((job: JobData) => {
            const jobStartDate = job.start ? new Date(job.start) : null;
            const jobEndDate = job.end ? new Date(job.end) : null;

            return (
              (jobEndDate && jobEndDate.getTime() < formattedFirstDateOfWeek.getTime()) ||
              (jobStartDate && jobStartDate.getTime() > formattedLastDateOfWeek.getTime())
            );
          });
          setDraftJobs(filteredDraftJobs);
          break;
        }
        case CalendarMode.DAY: {
          const filteredDraftJobs = jobs.data.filter((job: JobData) => {
            const jobStartDate = job.start ? new Date(job.start) : null;
            const jobEndDate = job.end ? new Date(job.end) : null;

            return (jobStartDate && isToday(jobStartDate)) || (jobEndDate && isToday(jobEndDate));
          });
          setDraftJobs(filteredDraftJobs);
          break;
        }
      }
    }
  }

  async function getDraftsWithoutDate(user: User | undefined) {
    if (user && user.userType === UserType.ADMIN) {
      const jobs = await getDraftsWithoutDates();
      setDraftJobsWithoutDate(jobs.data);
    }
  }

  async function toJobOrSlinga(data: any, docId: string) {
    // SLINGA
    if (data.sNumber !== undefined) {
      const slinga: SlingaDataPartial = await createSlingaDataPartial(docId, data as SlingaDoc);
      return slinga;

      // JOB
    } else {
      const jobDataPartial: JobDataPartial = await toJobDataPartial(docId, data as JobDoc);
      return jobDataPartial;
    }
  }

  return (
    <JobsContext.Provider
      value={{
        jobs,
        setJobs,
        filteredJobs,
        setFilteredJobs,
        vehicleFilter,
        setVehicleFilter,
        littraFilter,
        setLittraFilter,
        driverFilter,
        setDriverFilter,
        clientFilter,
        setClientFilter,
        loading,
        showOnlyJobsToHandle,
        setShowOnlyJobsToHandle,
        showOnlySlingor,
        setShowOnlySlingor,
        setListenerOnJobsCollection,
        filterJobs,
        getDraftsOtherWeeks,
        draftJobs,
        getDraftsWithoutDate,
        draftJobsWithoutDate,
        showDrafts,
        setShowDrafts,
        selectedJobs,
        setSelectedJobs,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
}
