import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PdfJobsContext } from '../../contexts/PdfJobsContext';
import { PopupContext } from '../../contexts/PopupContext';
import { updateJob } from '../../firebase/firestore_functions/job';
import { JobStatus, NotificationStatus, UserType } from '../../utils/constants';
import { sendSMS } from '../../utils/otherHelpers';
import { JobData } from '../../utils/types';
import AddEditJobMain from '../add_job/AddEditJobMain';

type Props = {
  details: JobData;
};

/**
 * Includes all the controls which an administrator can use to change the job
 */
export default function AdminControls({ details }: Props) {
  const { user } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);
  const { showPopup, close } = useContext(PopupContext);
  const { jobs: pdfJobs, onPdfJobChange } = useContext(PdfJobsContext);

  async function updateStatusToValidated() {
    if (details) {
      const updateStatusResponse = await updateJob(details.docId, {
        status: JobStatus.VALIDATED,
      });

      if (updateStatusResponse.code === 201) {
        notify('Jobbet har attesterats', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Jobbet kunde inte attesteras: ${updateStatusResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  async function updateStatusToPayed() {
    if (details) {
      const updateStatusResponse = await updateJob(details.docId, {
        status: JobStatus.PAYED,
      });

      if (updateStatusResponse.code === 201) {
        notify('Jobbet har attesterats', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Jobbet kunde inte attesteras: ${updateStatusResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }
  async function updateStatusToInvoiced() {
    if (details) {
      const updateStatusResponse = await updateJob(details.docId, {
        status: JobStatus.INVOICED,
      });

      if (updateStatusResponse.code === 201) {
        notify('Jobbet har attesterats', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Jobbet kunde inte attesteras: ${updateStatusResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  function sendLinkToDriver() {
    // Subcontractors (underleverantörer) gets a link that leads them directly to a details view for the job. A inhouse driver gets a link to the apps main page since this
    // will show them their schedule where they can see the job listed.

    // '!' is used after details because we are sure that it is not undefined. The button that has this function as an onClick handler is not rendered if details are undefined.
    if (details!.subcontractor && details!.contactSubcontractor) {
      sendSMS(
        `Hej ${
          details!.contactSubcontractor.name
        }!\n\nDu har ett nytt uppdrag från Nolblad:\nhttps://nolblad.se/job-${details!.docId}`,
        details!.contactSubcontractor.phone,
      );
    } else if (details!.driver?.phone) {
      sendSMS(
        `Hej ${
          details!.driver.firstName
        }!\n\nDu har ett nytt uppdrag från Nolblad:\nhttps://nolblad.se/`,
        details!.driver.phone,
      );
    } else {
      notify(
        'Finns ingen information om telefonnummer till förare eller underleverantör',
        NotificationStatus.INFO,
      );
      return;
    }

    if (!details!.smsHasBeenSent) updateJob(details!.docId, { smsHasBeenSent: true, status: 0 });
    notify('SMS har skickats till förare', NotificationStatus.SUCCESS);
  }

  function onDeleteJob() {
    // need to remove the job from the selected jobs for a pdf
    if (pdfJobs.has(details.docId)) {
      onPdfJobChange(details.docId);
    }
    close();
  }

  function openEditJob() {
    showPopup(<AddEditJobMain job={details} onDeleteJob={onDeleteJob} />, 'big');
  }

  const showAdminControls =
    user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType);

  if (!showAdminControls) return null;

  return (
    <>
      {details.status === JobStatus.REPORTED && user.userType === UserType.ADMIN && (
        <button className='fa fa-check button--edit' onClick={updateStatusToValidated}>
          Attestera
        </button>
      )}
      {/* When hasReceiptForCompany is true you should be able to update status to "PAYED" */}
      {/* When hasReceiptForCompany is false you should be able to update status to "INVOICED" */}
      {details.status === JobStatus.VALIDATED && user.userType === UserType.ADMIN && (
        <button className='fa fa-check button--icon' onClick={updateStatusToInvoiced}>
          Fakturerat
        </button>
      )}

      {/* Fakturerad to Leverantör betald flow */}

      {details.status === JobStatus.INVOICED &&
        user.userType === UserType.ADMIN &&
        (details.hasReceiptsForCompany ? (
          <button className='fa fa-check button--primary' onClick={updateStatusToPayed}>
            Leverantör Betalt
          </button>
        ) : (
          <> </>
        ))}

      {!details.slinga && (
        <button className='fa fa-edit button--edit' onClick={openEditJob}>
          Redigera
        </button>
      )}
      <button className='fa fa-share button--primary' onClick={sendLinkToDriver}>
        Skicka länk till förare
      </button>
    </>
  );
}
