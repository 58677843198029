import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { PopupContext } from '../../contexts/PopupContext';
import { AddEditJobContext } from './AddEditJobContext';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import { ContactData, LittraData, LittraDoc } from '../../utils/types';
import { collection, getDoc, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { ReactComponent as SelectIcon } from '../../images/selectIcon.svg';

import AddLittraPopup from '../AddLittra';
import { JobType } from '../../utils/constants';

const db = getFirestore();

export default function AddLittra() {
  const { showPopup } = useContext(PopupContext);
  const {
    jobType,
    client,
    littra,
    littraTemp,
    littraWorkplaceTemp,
    setLittraWorkplaceTemp,
    setLittra,
    setLittraTemp,
    setContactClient,
    setFrom,
    setTo,
    setOtherInformation,
  } = useContext(AddEditJobContext);

  const [littras, setLittras] = useState<LittraData[]>([]);
  const [loadingLittras, setLoadingLittras] = useState<boolean>(false);

  useEffect(() => {
    return updateLittrasListener();
  }, [client]);
  useEffect(updateStateAccordingToLittra, [littra]);

  function updateLittrasListener() {
    onChangeLittra('');
    if (client) {
      // subscribe to littras
      setLoadingLittras(true);
      const qLittras = query(collection(db, `clients/${client.docId}/littras`));

      const unsubscribeLittras = onSnapshot(qLittras, async (querySnapshot) => {
        let _littras: LittraData[] = [...littras];

        const changes = querySnapshot.docChanges();

        for (const change of changes) {
          if (change.type === 'added' || change.type === 'modified') {
            const data = change.doc.data() as LittraDoc;
            const littra = {
              ...(data as LittraData),
              docId: change.doc.id,
            };

            if (data.contact) {
              // populate contact
              const contactDoc = await getDoc(data.contact);

              littra.contact = {
                ...(contactDoc.data() as ContactData),
                docId: contactDoc.id,
              };
            }
            _littras.push(littra);
          } else if (change.type === 'removed') {
            _littras = _littras.filter((l) => l.docId !== change.doc.id);
          }
        }

        setLittras(_littras);
        setLoadingLittras(false);
      });

      return unsubscribeLittras;
    }
  }

  function onAddLittra(littra: LittraData) {
    const _littras = [...littras, littra];
    setLittras(_littras);
    setLittra(littra);
  }

  function onChangeLittra(value: string) {
    const _littra = littras.find((l) => l.docId === value);
    setLittra(_littra);
  }

  function updateStateAccordingToLittra() {
    if (littra) {
      setLittra(littra);
      setFrom(littra.from);

      jobType !== JobType.EXCAVATOR_MAN && setTo(littra.to);
      setContactClient(littra.contact);
      littra.otherInformation && setOtherInformation(littra.otherInformation);
    }
  }

  function onChangeLittraTemp(event: SyntheticEvent) {
    setLittraTemp((event.target as HTMLInputElement).value);
  }

  function onChangeLittraWorkplaceTemp(event: SyntheticEvent) {
    setLittraWorkplaceTemp((event.target as HTMLInputElement).value);
  }

  function openAddLittra(event: SyntheticEvent) {
    event.preventDefault();
    client && showPopup(<AddLittraPopup client={client} onAdd={onAddLittra} />, 'medium');
  }

  return (
    <li key='littra' className='add-job__form__li' id='add-job__form__li-littra'>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <p style={{ marginBottom: '15px', marginTop: '15px' }} className='label'>
          Littra
        </p>
        <input
          type='text'
          id='littra-temp-input'
          className='input-text add-job__form__input'
          value={littraTemp}
          placeholder='Littra'
          autoComplete='off'
          onChange={onChangeLittraTemp}
        />
        {littraTemp && littraTemp !== '' && (
          <button
            onClick={() => {
              setLittraTemp('');
              setLittraWorkplaceTemp('');
            }}
            style={{ position: 'absolute', right: '2rem' }}
            className='fa fa-times'
          />
        )}
      </div>

      {littraTemp && littraTemp !== '' && (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <input
            id='littra'
            type='text'
            className='input-text add-job__form__input'
            value={littraWorkplaceTemp}
            placeholder='Temporär Arbetesplats'
            autoComplete='off'
            onChange={onChangeLittraWorkplaceTemp}
          />
          {littraWorkplaceTemp && littraWorkplaceTemp !== '' && (
            <button
              onClick={() => setLittraWorkplaceTemp('')}
              style={{ position: 'absolute', right: '2rem' }}
              className='fa fa-times'
            />
          )}
        </div>
      )}

      {littraTemp === '' && (
        <>
          <p style={{ marginBottom: '15px', marginTop: '15px' }} className='label'>
            Sparade littra
          </p>
          <Select
            isMultiSelect={false}
            type='form'
            searchBar={true}
            label='Sparade littras'
            onChange={onChangeLittra}
            iconRightClose={<SelectIcon />}
            iconRightOpen={<SelectIcon />}
            initialOption={littra ? { value: littra.docId, label: littra.projectNum } : undefined}
          >
            <>
              {loadingLittras ? (
                <div className='option'>Laddar littras...</div>
              ) : (
                littras.map((l: LittraData) => {
                  return <Option key={l.docId} value={l.docId} label={l.projectNum} />;
                })
              )}
            </>
          </Select>
        </>
      )}

      {/* <button id='button--add-littra' className='button--main--small' onClick={openAddLittra}>
        <span className='fa fa-plus' />
        Lägg till littra
      </button> */}
    </li>
  );
}
