import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import ImagePopup from '../../components/ImagePopup';
import Textarea from '../../components/Textarea';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import { toJobData, updateJob } from '../../firebase/firestore_functions/job';
import {
  JobStatus,
  jobStatusLabel,
  JobType,
  NotificationStatus,
  UserType,
} from '../../utils/constants';
import {
  getContactClientTempName,
  getContactClientTempPhone,
  getLittraText,
  getLittraWorkText,
} from '../../utils/jobUtils';
import { toTimeDateString, toTimeDateStringVariant } from '../../utils/time';
import { ChosenMaterials, JobData, JobDoc } from '../../utils/types';
import { stopPropagation } from '../../utils/uiHelpers';
import ReportJob from '../report_job';
import ReportJobFinal from '../report_job/ReportJobFinal';
import ReportSummary from '../report_job/ReportSummary';
import AdminControls from './AdminControls';
import DriverControls from './DriverControls';
import './JobDetails.css';
import { getJobStatusLabel, getPartialJobBackgroundColor } from '../../utils/styling';
import OrderNumber from '../OrderNumber';
import JobDetail from './JobDetail';
import {
  Briefcase,
  Calendar,
  Clock,
  Flag,
  Image,
  LayoutGrid,
  Loader,
  MapPin,
  Maximize2,
  MessageSquare,
  PenLine,
  Phone,
  Truck,
  User,
  UserSquare,
  Package,
} from 'lucide-react';
import JobDetailsDivider from './JobDetailsDivider';
import ShowPdfs from '../pdf/ShowPdfs';
import PdfButton from '../../components/pdf/PdfButton';
import { ReactComponent as GreenCheck } from '../../images/greenCheck.svg';
import { ReactComponent as RedCross } from '../../images/redCross.svg';

interface JobDetailsProps {
  jobDocId: string;
  calendarClickedDate?: number;
  belongsToSlinga?: boolean;
}

const db = getFirestore();

/**
 * Component that shows all information in a job.
 * @param jobDocId id of job in firestore
 * @returns JSX.Element
 */
const JobDetails: React.FC<JobDetailsProps> = ({
  jobDocId,
  belongsToSlinga,
  calendarClickedDate,
}) => {
  const { notify } = useContext(NotificationContext);
  const { user } = useContext(AuthContext);
  const { showPopup } = useContext(PopupContext);

  const [details, setDetails] = useState<JobData>();
  const [adminComments, setAdminComments] = useState(''); // to be able to update these without entering edit mode
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setListenerToJobDoc().then((unsubscribe) => {
      return unsubscribe;
    });
  }, []);

  /**
   * Sets listener to job doc to reflect changes made to the doc live
   */
  async function setListenerToJobDoc() {
    const jobDocRef = doc(db, 'jobs', jobDocId);
    const unsubscribe = onSnapshot(jobDocRef, async (querySnapshot) => {
      if (querySnapshot.exists()) {
        const jobDoc = querySnapshot.data() as JobDoc;
        const job = await toJobData(jobDocId, jobDoc);
        console.log(job);
        setDetails(job);
        setAdminComments(job.adminComments ? job.adminComments : '');
        checkShouldChangeStatusFromNewToReceived(job);
      } else {
        setNotFound(true);
      }
    });

    return unsubscribe;
  }

  async function checkShouldChangeStatusFromNewToReceived(job: JobData) {
    if (job.driver?.docId === user?.docId && job.status === JobStatus.NEW) {
      const updateStatusResponse = await updateJob(job.docId, {
        status: JobStatus.RECEVIED,
      });
      if (updateStatusResponse.code !== 201) {
        console.log('Kunde inte uppdatera status till mottagen av förare');
      }
    }
  }

  async function saveAdminComment() {
    if (adminComments && details) {
      const saveAdminCommentResponse = await updateJob(details.docId, {
        adminComments,
      });
      if (saveAdminCommentResponse.code === 201) {
        notify('Kommentarerna är sparade', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Kunde inte spara kommentarerna: ${saveAdminCommentResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  function showImagePopup(image: string) {
    showPopup(<ImagePopup image={image} />, 'medium');
  }

  function openReportJobFinal() {
    details && showPopup(<ReportJobFinal job={details} />, 'medium');
  }

  function openReportJob() {
    details &&
      showPopup(
        <ReportJob
          job={details}
          initialDate={calendarClickedDate !== undefined ? calendarClickedDate : details.start || 0}
        />,
        'medium',
      );
  }

  const formatPhoneNumber = (phone: string | undefined) => {
    if (!phone) {
      return '';
    }

    //Remove spaces
    phone = phone.replace(/\s/g, '');
    //CHeck if country code exists
    if (phone.charAt(0) === '+') {
      return phone;
    }

    //Check if first number is 0
    if (phone.charAt(0) === '0') {
      phone = phone.slice(1);
    }

    if (phone.startsWith('46')) {
      phone = phone.slice(2);
    }

    //Add country code to phone number
    phone = '+46' + phone;
    return phone;
  };

  function isChosenMaterials(obj: any): obj is ChosenMaterials {
    if (typeof obj !== 'object' || obj === null) return false;
    return true;
  }

  return (
    <>
      {notFound && (
        <section className='details' onClick={stopPropagation}>
          <h2>Uppdraget kunde inte hittas.</h2>
        </section>
      )}

      {!notFound && !details && (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      )}
      {details && (
        <div className='details' onClick={stopPropagation}>
          <section className='details-section' style={{ marginTop: '1rem', display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '30px',
              }}
            >
              <OrderNumber orderNumber={details.orderNum} />
              <div
                className={`status`}
                style={{
                  backgroundColor: getPartialJobBackgroundColor(details, user),
                  marginLeft: '24px',
                  padding: '8px 12px',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <JobDetail text1='status: '>{getJobStatusLabel(details)}</JobDetail>
              </div>
            </div>

            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ marginBottom: '15px' }}></div>
              <div className='job-details__left'>
                <h1 className='h1-job-details'>{details.client?.name}</h1>
                <div style={{ marginBottom: '15px' }}></div>
                <section className='details-section'>
                  {details.littra && !details.littraTemp && (
                    <>
                      <JobDetail text1='Littra:' text2={getLittraText(details)} />
                      <JobDetail text1='Arbetsplats:' text2={getLittraWorkText(details)} />
                    </>
                  )}
                  {details.contactClient && !details.contactClientTemp && (
                    <JobDetail text1='Kontakt:'>
                      <div>
                        {details.contactClient.name}
                        {`, `}
                        <a href={`tel:${formatPhoneNumber(details.contactClient.phone)}`}>
                          {details.contactClient.phone}
                        </a>
                      </div>
                    </JobDetail>
                  )}
                  {!details.contactClient && details.contactClientTemp && (
                    <JobDetail text1='Kontakt:'>
                      <div>
                        <ul>
                          {details.contactClientTemp.map((contact, index) => (
                            <li key={index} style={{ marginBottom: '10px' }}>
                              {contact.name}, {contact.phone}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </JobDetail>
                  )}

                  {details.littraTemp && !details.littra && (
                    <>
                      <JobDetail
                        icon={<LayoutGrid size={16} strokeWidth={3} />}
                        text1='Littra:'
                        text2={getLittraText(details)}
                      />
                      <JobDetail
                        icon={<Briefcase size={16} strokeWidth={3} />}
                        text1='Arbetsplats:'
                        text2={getLittraWorkText(details)}
                      />
                    </>
                  )}

                  <div style={{ marginBottom: '15px' }}></div>
                  {details.otherMaterials && (
                    <JobDetail
                      icon={<Package size={16} strokeWidth={3} />}
                      text1='Godsslag:'
                      text2={details.otherMaterials}
                    />
                  )}
                </section>

                <JobDetail
                  text1='Start'
                  text2={details.start ? toTimeDateStringVariant(details.start) : 'inget datum'}
                />
                <div style={{ marginBottom: '15px' }}></div>
                <JobDetail
                  text1='Slut'
                  text2={details.end ? toTimeDateStringVariant(details.end) : 'inget datum'}
                />
                <div style={{ marginBottom: '15px' }}></div>
                {details.driver && (
                  <JobDetail
                    text1='Förare:'
                    text2={
                      details.driver.firstName +
                      ' ' +
                      details.driver.lastName +
                      '    ' +
                      details.driver.phone
                    }
                  />
                )}
              </div>
              <div className='button--container'>
                {user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
                  <>
                    {/* <DriverControls details={details} calendarClickedDate={calendarClickedDate} /> */}
                    <div style={{ marginBottom: '15px' }}></div>
                    <AdminControls details={details} />
                  </>
                ) : (
                  <DriverControls details={details} calendarClickedDate={calendarClickedDate} />
                )}
              </div>
            </div>

            <div style={{ marginBottom: '15px' }}></div>
          </section>

          <section className='details-section'>
            {/* Vehicle */}
            {details.vehicle && <JobDetail text1='Resurs' text2={details.vehicle.id} />}
            <JobDetail text1='Signerad' text2={details.signed ? 'Ja ' : 'Nej '}>
              <div style={{ marginLeft: '8px' }}>
                {details.signed ? <GreenCheck /> : <RedCross />}
              </div>
            </JobDetail>
            <JobDetail
              text1='Skickad för signering'
              text2={
                typeof details?.sendForSigningBy === 'string' ? details?.sendForSigningBy : 'Nej'
              }
            >
              <div style={{ marginLeft: '8px' }}>
                {typeof details?.sendForSigningBy === 'string' ? <GreenCheck /> : <RedCross />}
              </div>
            </JobDetail>

            <div style={{ marginBottom: '10px' }}></div>

            {details?.routes?.map((route, index) => {
              return (
                <>
                  <JobDetailsDivider number={index + 1} />
                  <div className='route-container'>
                    {route?.materials && isChosenMaterials(route.materials) ? (
                      Object.keys(route.materials).map((key) => (
                        <div className='route-container' key={key}>
                          <JobDetail text1='Material: '>
                            {isChosenMaterials(route.materials) &&
                              (route.materials[key].material.description as string)}
                          </JobDetail>
                          <JobDetail text1='Antal lass: '>
                            {isChosenMaterials(route.materials) &&
                              route.materials[key].howMuch[0].qty}
                          </JobDetail>
                          <JobDetail text1='Antal ton: '>
                            {isChosenMaterials(route.materials) &&
                              route.materials[key].howMuch[1].qty}
                          </JobDetail>
                        </div>
                      ))
                    ) : (
                      <JobDetail text1='Godsslag'>{route.materials}</JobDetail>
                    )}

                    {route?.from && (
                      <JobDetail text1='Från'>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${route?.from.position?.lat},${route?.from.position?.lng}`}
                        >
                          {route?.from.address || route?.from.name}
                        </a>
                      </JobDetail>
                    )}
                    {route?.to && (
                      <JobDetail text1='Till'>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${route.to.position?.lat},${route.to.position?.lng}`}
                        >
                          {route.to.address || route.to.name}
                        </a>
                      </JobDetail>
                    )}
                  </div>
                </>
              );
            })}

            {/* Från */}
            <div className='route-container'>
              {details?.from && (
                <JobDetail text1='Från'>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${details.from.position?.lat},${details.from.position?.lng}`}
                  >
                    {details.from.address || details.from.name}
                  </a>
                </JobDetail>
              )}

              {/* Till */}
              {details?.to && (
                <JobDetail text1='Till'>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${details.to.position?.lat},${details.to.position?.lng}`}
                  >
                    {details.to.address || details.to.name}
                  </a>
                </JobDetail>
              )}

              {/* Estimerad sträcka */}
              {details?.estimatedDistance && (
                <JobDetail text1='Estimerad sträcka:' text2={`${details.estimatedDistance.text}`} />
              )}

              {/* Estimerad tid */}
              {details?.estimatedTime && (
                <JobDetail text1='Estimerad tid:' text2={`${details.estimatedTime.text}`} />
              )}

              {/* Annan information */}
              {details?.otherInformation && (
                <JobDetail text1='Kommentar till chaufför:' childrenOnNewLine={true}>
                  <p
                    style={{
                      color: 'var(--Text, #464646)',
                      fontFamily: 'Open Sans',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                    }}
                  >
                    {details.otherInformation}
                  </p>
                </JobDetail>
              )}
            </div>

            <div style={{ marginBottom: '7px' }}></div>

            {details.images && details.images.length > 0 && (
              <JobDetail text1='Bilder:' childrenOnNewLine={true}>
                <ul id='images__ul'>
                  {details.images.map((image, idx) => {
                    return (
                      <li className='images__li' key={idx} onClick={() => showImagePopup(image)}>
                        <img className='img' src={image} alt='' />
                      </li>
                    );
                  })}
                </ul>
              </JobDetail>
            )}
          </section>
          {details.subcontractor && (
            <>
              <section className='details-section'>
                <JobDetail text1='Underleverantör:' text2={details.subcontractor.name} />
                {details.contactSubcontractor && (
                  <JobDetail text1='Kontakt:'>
                    <div>
                      {details.contactSubcontractor.name}
                      {`, `}
                      <a href={`tel:${formatPhoneNumber(details.contactSubcontractor.phone)}`}>
                        {details.contactSubcontractor.phone}
                      </a>
                    </div>
                  </JobDetail>
                )}
              </section>
            </>
          )}

          <JobDetailsDivider doNotShowLine={details.reports?.length ? false : true} />

          {details.reports && (
            <>
              <ReportSummary job={details} showSensitiveData={true} />
              <JobDetailsDivider doNotShowLine={true} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default JobDetails;
