import { View, Text, Image } from '@react-pdf/renderer';
import { IRoute, JobData } from '../../utils/types';
import PdfReport from './PdfReport';
import styles from './PDFStyles';
import { toDayDateString } from '../../utils/time';
import { getJobSignature, getTotalHours } from '../../utils/jobUtils';
import { JobType } from '../../utils/constants';
import { ro } from 'date-fns/locale';

export type Props = {
  job: JobData;
};

const OrderRow = ({ job }: Props) => {
  // Sort reports by start time
  if (job.reports && job.reports.length > 0) {
    job.reports.sort((a, b) => {
      return a.start < b.start ? -1 : 1;
    });
  }

  const totalHours = job.reports ? getTotalHours(job.reports) : null;
  const totalMiles = getTotalMiles();

  /**
   * Gets the number of miles driven in the job in total. If no miles is reportet it returnes null.
   */
  function getTotalMiles() {
    if (job.reports) {
      const totalMiles = job.reports.reduce((currentSum, report) => {
        return currentSum + report.miles;
      }, 0);

      return totalMiles > 0 ? totalMiles : null;
    }

    return null;
  }

  /**
   * Constructs the service literal displayed under "Tjänst"
   *
   * */
  function getServiceLiteral() {
    let literal = '';
    // if (job.vehicle) literal += job.vehicle.service;
    // job.vehicleEquipments?.forEach((eq) => {
    //   literal += `, ${eq.service}`;
    // });
    if (!job) return '';
    if (!job.vehicle && !job.vehicleEquipments) return '';

    literal += job.vehicle?.id;

    literal += '(';

    literal += job.vehicle?.serviceId;

    literal += ')';

    literal += ' ';

    job.vehicleEquipments?.forEach((eq) => {
      literal += `${eq.service}(${eq.serviceId}), `;
    });

    // remove last comma
    literal = literal.substring(0, literal.length - 2);

    return literal;
  }

  const sumQtyUnitPerArticle = (routes: IRoute[]) => {
    const result: { [unit: string]: number } = {};

    routes.forEach((route) => {
      if (typeof route === 'string') return;
      if (!route.materials || typeof route.materials === 'string') return;
      for (const key in route.materials) {
        const material = route.materials[key];
        material.howMuch.forEach((item) => {
          if (result[item.unit]) {
            result[item.unit] += item.qty;
          } else {
            result[item.unit] = item.qty;
          }
        });
      }
    });

    return result;
  };

  const sumQtyUnit = sumQtyUnitPerArticle(job?.routes || []);

  return (
    <View style={{ ...styles.section }} key={Math.random()}>
      {/* Map the reports in tables */}
      {job.jobType === JobType.TRANSPORT
        ? !!job?.routes &&
          job?.routes.length > 0 &&
          job?.routes.map((route, idx) => {
            return (
              <View
                key={idx}
                style={{
                  display: 'flex',
                  backgroundColor: idx % 2 === 0 ? 'white' : 'rgba(239, 239, 239, 0.40)',
                  minHeight: '30px',
                }}
              >
                <PdfReport route={route} job={job} />
              </View>
            );
          })
        : !!job?.reports &&
          job?.reports.length > 0 &&
          job?.reports.map((report, idx) => {
            return (
              <View
                key={idx}
                style={{
                  display: 'flex',
                  backgroundColor: idx % 2 === 0 ? 'white' : 'rgba(239, 239, 239, 0.40)',
                  minHeight: '30px',
                }}
              >
                <PdfReport job={job} report={report} />
              </View>
            );
          })}

      {job?.reports && job?.reports?.length > 5 && <Text break />}

      {/* {job?.jobType === JobType.TRANSPORT && job?.otherInformation && (
        <View style={{ minHeight: '50px' }}>
          <Text style={styles.tableBody}>Kommentar till kund</Text>
          <Text style={styles.tableBody}>{job?.otherInformation}</Text>
        </View>
      )} */}

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: '20px',
        }}
      >
        {!!job.routes &&
          job.routes.some((route) => route.materialFrom === 'Material uttaget på Nolblads') && (
            <View style={{ backgroundColor: 'rgba(229, 9, 127, 0.05)', width: '45%' }}>
              <Text style={{ ...styles.tableBody, padding: '8px' }}>Uttaget på Nolblads</Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text style={styles.tableBody}>Artikelnr</Text>
                <Text style={styles.tableBody}>Tjänst/gods</Text>
                <Text style={styles.tableBody}>Antal</Text>
                <Text style={styles.tableBody}>Enhet</Text>
              </View>
              {Object.entries(sumQtyUnit).map((item) => (
                <View
                  key={item[0]}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '8px',
                  }}
                >
                  <Text style={styles.tableBody}>{job.vehicle?.serviceId}</Text>
                  <Text style={styles.tableBody}>{job.vehicle?.service}</Text>
                  <Text style={styles.tableBody}>{item[1]}</Text>
                  <Text style={styles.tableBody}>{item[0]}</Text>
                </View>
              ))}
            </View>
          )}
        {!!job.routes &&
          job.routes.some((route) => route.materialFrom === 'Material uttaget på kund') && (
            <View style={{ backgroundColor: 'rgba(229, 9, 127, 0.05)', width: '45%' }}>
              <Text style={{ ...styles.tableBody, padding: '8px' }}>Uttaget på kund</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text style={styles.tableBody}>Artikelnr</Text>
                <Text style={styles.tableBody}>Tjänst/gods</Text>
                <Text style={styles.tableBody}>Antal</Text>
                <Text style={styles.tableBody}>Enhet</Text>
              </View>

              {/* {Object.entries(sumQtyUnit).map((item) => (
                <View
                  key={item[0]}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '8px',
                  }}
                >
                  <Text style={styles.tableBody}>{job.vehicle?.serviceId}</Text>
                  <Text style={styles.tableBody}>{job.vehicle?.service}</Text>
                  <Text style={styles.tableBody}>{item[1]}</Text>
                  <Text style={styles.tableBody}>{item[0]}</Text>
                </View>
              ))} */}
            </View>
          )}
      </View>

      <View style={{ position: 'relative', height: '100%' }}>
        <View style={{ ...styles.pageNumber }}>
          <Text
            style={{
              ...styles.body,
              borderBottom: '1px solid black',
              paddingBottom: '12px',
              marginTop: '30pt',
            }}
          ></Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between' }}>
            <View style={{ ...styles.column, width: '33%' }}>
              <Text style={styles.body}>Signatur</Text>
              {job.signed && <Image src={getJobSignature(job)} />}
            </View>
            <View style={{ ...styles.column, width: '33%' }}>
              <Text style={{ ...styles.body, marginBottom: '5px' }}>Namnförtydligande</Text>
              <Text style={{ ...styles.body, marginBottom: '5px' }}>
                {job.signedBy && job.signedBy.name}
              </Text>
              <Text style={{ ...styles.body, marginBottom: '5px' }}>Kontakt</Text>
              <Text style={{ ...styles.body, marginBottom: '5px' }}>
                {job.signedBy && job.signedBy.email}
              </Text>
            </View>
            <View style={{ ...styles.column, width: '33%' }}>
              <Text style={styles.body}>Kommentar</Text>
              {job.signed && <Text style={styles.body}>{job.signedBy?.comment}</Text>}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OrderRow;
