import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import { DataContext } from '../../contexts/CommonDataContext';
import { ClientData, Littra, User, VehicleData } from '../../utils/types';
import './CalendarFilters.css';
import { getLittrasForClient } from '../../firebase/firestore_functions/clients';
import sliders from '../../images/sliders.svg';
import { FilterSelect } from '../../components/select/FilterSelect';
import CalendarControls from '../../components/calendar_controls/CalendarControls';
import { Switch } from 'antd';
import { JobsContext } from '../../contexts/JobsContext';

interface CalendarFiltersProps {
  showOnlyJobsToHandle: boolean;
  onChangeShowOnlyJobsToHandle: Dispatch<SetStateAction<boolean>>;
  showOnlySlingor: boolean;
  onChangeShowOnlySlingor: Dispatch<SetStateAction<boolean>>;
  driverFilter: Set<string>;
  onChangeDriverFilter: Dispatch<SetStateAction<Set<string>>>;
  clientFilter: Set<string>;
  onChangeClientFilter: Dispatch<SetStateAction<Set<string>>>;
  vehicleFilter: Set<string>;
  onChangeVehicleFilter: Dispatch<SetStateAction<Set<string>>>;
  littraFilter: Set<string>;
  onChangeLittraFilter: Dispatch<SetStateAction<Set<string>>>;
}

/**
 * Component used for admin and extended drivers to filter for client, vehicle or driver in the calendar
 * @returns JSX.Element
 */
export default function CalendarFilters({
  driverFilter,
  onChangeDriverFilter,
  clientFilter,
  onChangeClientFilter,
  vehicleFilter,
  onChangeVehicleFilter,
  showOnlyJobsToHandle,
  onChangeShowOnlyJobsToHandle,
  showOnlySlingor,
  onChangeShowOnlySlingor,
  littraFilter,
  onChangeLittraFilter,
}: CalendarFiltersProps) {
  const { showDrafts, setShowDrafts } = useContext(JobsContext);
  const { drivers, clients, vehicles, clientsLoading } = useContext(DataContext);
  const [_driverFilter, setDriverFilter] = useState<Set<string>>(driverFilter);
  const [_vehicleFilter, setVehicleFilter] = useState<Set<string>>(vehicleFilter);
  const [_clientFilter, setClientFilter] = useState<Set<string>>(clientFilter);
  const [_littraFilter, setLittraFilter] = useState<Set<string>>(littraFilter);
  const [_showOnlyJobsToHandle, setShowOnlyJobsToHandle] = useState(showOnlyJobsToHandle);
  const [_showOnlySlingor, setShowOnlySlingor] = useState(showOnlySlingor);
  const [littraOptions, setLittraOptions] = useState<Littra[]>([]);

  function getUpdatedFilter(value: string, oldFilter: Set<string>, action?: boolean) {
    const newFilter = new Set(oldFilter);

    if (action) {
      newFilter.add(value);
    } else {
      newFilter.delete(value);
    }

    return newFilter;
  }

  function _onChangeClientFilter(value: string, action?: boolean) {
    const newFilter = getUpdatedFilter(value, _clientFilter, action);
    setClientFilter(newFilter);
    onChangeClientFilter(newFilter);
    updateLittraProjectNums(newFilter);
  }

  const updateLittraProjectNums = async (clientFilter: Set<string>) => {
    // Get littras for all the clients
    let allLittras: Littra[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const docId of clientFilter.values()) {
      const littras = await getLittrasForClient(docId);
      allLittras = allLittras.concat([...littras]);
    }
    setLittraOptions(allLittras);
  };

  function _onChangeVehicleFilter(value: string, action?: boolean) {
    const newFilter = getUpdatedFilter(value, _vehicleFilter, action);
    setVehicleFilter(newFilter);
    onChangeVehicleFilter(newFilter);
  }

  function _onChangeDriverFilter(value: string, action?: boolean) {
    const newFilter = getUpdatedFilter(value, _driverFilter, action);
    setDriverFilter(newFilter);
    onChangeDriverFilter(newFilter);
  }

  function _onChangeShowOnlyJobsToHandle() {
    setShowOnlyJobsToHandle(!_showOnlyJobsToHandle);
    onChangeShowOnlyJobsToHandle(!_showOnlyJobsToHandle);
  }

  function _onChangeShowOnlySlingor() {
    setShowOnlySlingor(!_showOnlySlingor);
    onChangeShowOnlySlingor(!_showOnlySlingor);
  }

  function _onChangeLittraFilter(value: string, action?: boolean) {
    const newFilter = getUpdatedFilter(value, _littraFilter, action);
    setLittraFilter(newFilter);
    onChangeLittraFilter(newFilter);
  }

  return (
    <section id='container'>
      <section id='filters'>
        <div
          className='filter__item'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <label
            htmlFor='calendar__controls__left__filter'
            id='calendar__controls__left__filter-label'
          >
            Dagvy
          </label>
          <Switch defaultChecked />
        </div>

        {location.pathname !== '/logga-in' && <CalendarControls />}
        {/* <div id='filter-header'>
          <img src={sliders} id='logo' />
          <h3 id='text'>Filter</h3>
        </div> */}

        <FilterSelect
          drivers={drivers}
          clients={clients}
          vehicles={vehicles}
          clientsLoading={clientsLoading}
          littraOptions={littraOptions}
          onChangeDriverFilter={_onChangeDriverFilter}
          onChangeClientFilter={_onChangeClientFilter}
          onChangeVehicleFilter={_onChangeVehicleFilter}
          onChangeLittraFilter={_onChangeLittraFilter}
          driverFilter={_driverFilter}
          clientFilter={_clientFilter}
          vehicleFilter={_vehicleFilter}
          littraFilter={_littraFilter}
        />

        {/* <div className='filter'>
          {clientFilter.size > 0 && (
            <Select
              isMultiSelect={true}
              type='button'
              label='Littra'
              onChange={_onChangeLittraFilter}
              iconRightClose='fa fa-angle-down'
              iconRightOpen='fa fa-angle-up'
              showLabel={true}
              searchBar={true}
              values={_littraFilter}
            >
              <>
                {littraOptions.map((l: Littra) => {
                  return (
                    <Option
                      key={l.projectNum}
                      value={l.projectNum}
                      label={l.projectNum}
                      checkbox={true}
                    />
                  );
                })}
              </>
            </Select>
          )}
        </div> */}
        <div className='filter'>
          {/* Att hantera filter */}
          <div
            className='filter__item'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <input
              id='calendar__controls__left__filter'
              className='checkbox'
              type='checkbox'
              checked={_showOnlyJobsToHandle}
              onChange={_onChangeShowOnlyJobsToHandle}
              style={{ marginRight: '10px' }}
            />
            <label
              htmlFor='calendar__controls__left__filter'
              id='calendar__controls__left__filter-label'
            >
              Att hantera
            </label>
          </div>
          {/* Endast slingor filter */}
          <div
            className='filter__item'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <input
              id='slinga_filter'
              className='checkbox'
              type='checkbox'
              checked={_showOnlySlingor}
              onChange={_onChangeShowOnlySlingor}
              style={{ marginRight: '10px' }}
            />
            <label htmlFor='slinga_filter' id='slinga_filter_label'>
              Endast slingor
            </label>
          </div>
          <div
            className='filter__item'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <input
              id='slinga_filter'
              className='checkbox'
              type='checkbox'
              checked={showDrafts}
              onChange={() => setShowDrafts(!showDrafts)}
              style={{ marginRight: '10px' }}
            />
            <label htmlFor='slinga_filter' id='slinga_filter_label'>
              Utakst
            </label>
          </div>
        </div>
      </section>
      <section>
        <ul className='filters__selected-list'>
          {Array.from(_driverFilter).map((df) => {
            return (
              <li className='filters__selected-item' key={df}>
                <p>{drivers.find((d) => d.docId === df)?.firstName}</p>
                <button
                  id='filters__selected-button'
                  onClick={() => _onChangeDriverFilter(df, false)}
                  className='fa fa-times'
                />
              </li>
            );
          })}
          {Array.from(_clientFilter).map((cf) => {
            return (
              <li className='filters__selected-item' key={cf}>
                <p style={{ flex: 1 }}>{clients.find((c) => c.docId === cf)?.name}</p>
                <button
                  id='filters__selected-button'
                  onClick={() => _onChangeClientFilter(cf, false)}
                  className='fa fa-times'
                />
              </li>
            );
          })}
          {Array.from(_vehicleFilter).map((vf) => {
            return (
              <li className='filters__selected-item' key={vf}>
                <p>{vehicles.find((v) => v.docId === vf)?.id}</p>
                <button
                  id='filters__selected-button'
                  onClick={() => _onChangeVehicleFilter(vf, false)}
                  className='fa fa-times'
                />
              </li>
            );
          })}
        </ul>
        {Array.from(_littraFilter).map((lf) => {
          const littra = littraOptions.find((l) => l.docId === lf);
          return (
            <li className='filters__selected-item' key={littra?.docId}>
              <p>{littra?.projectNum}</p>
              <button
                id='filters__selected-button'
                onClick={() => _onChangeLittraFilter(lf, false)}
                className='fa fa-times'
              />
            </li>
          );
        })}
      </section>
    </section>
  );
}
