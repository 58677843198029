import { JobStatus, UserType, jobStatusLabel } from './constants';
import { JobData, JobDataPartial, SlingaData, SlingaDataPartial } from './types';

export const jobStatusToStyling = (jobOrSlinga: JobDataPartial | SlingaDataPartial) => {
  // we need to map from the state of the job to ny of the status-... css classes
  // which alter the background color of the row
  if (jobOrSlinga.status !== JobStatus.INVOICED) {
    return `status-${jobOrSlinga.status}`;
  } else if (jobOrSlinga.isLejJob || jobOrSlinga.hasReceiptsForCompany) {
    // the case that the job is invoiced we need different
    // if the job is a Lej job or any receipts has been daken out on the company we shoudl return
    return 'status-6v2';
  } else {
    return 'status-6';
  }
};

export const getPartialJobBackgroundColor = (
  job: JobDataPartial | SlingaDataPartial | JobData,
  user: any,
  lighter?: boolean,
) => {
  const opacityValue = lighter ? 0.3 : 1;
  const opacityValueJobRecievedStatus = lighter ? 0.1 : 1;

  if (job.status === JobStatus.PAYED) {
    return 'rgba(0, 117, 68,' + opacityValue + ')';
  }

  if (job.status === JobStatus.INVOICED) {
    if (user?.userType === UserType.ADMIN || user?.userType === UserType.DRIVER_EXTENDED) {
      if (job.hasReceiptsForCompany) {
        return 'rgba(164, 62, 4,' + opacityValue + ')';
      }

      return 'rgba(0, 117, 68,' + opacityValue + ')';
    }
    return 'rgba(0, 117, 68,' + opacityValue + ')';
  }

  if (job.status === JobStatus.VALIDATED) {
    return 'rgba(141, 111, 2,' + opacityValue + ')';
  }

  if (job.status === JobStatus.REPORTED) {
    return 'rgba(100, 200, 255,' + opacityValue + ')';
  }

  if (job.end < Date.now() && job.status !== JobStatus.DRAFT) {
    // Förfallen
    return 'rgba(174, 14, 14,' + opacityValue + ')';
  }

  if (job.status === JobStatus.STARTED) {
    return 'rgba(64, 21, 190,' + opacityValue + ')';
  }

  if (job.status === JobStatus.RECEVIED) {
    return 'rgba(26, 55, 205,' + opacityValueJobRecievedStatus + ')';
  }

  if (job.status === JobStatus.NEW) {
    if (lighter) {
      return 'rgba(100, 100, 100, 0.1)';
    }

    return 'rgba(255, 255, 255, 1)';
  }

  if (job.status === JobStatus.DRAFT) {
    return 'rgba(135, 128, 128, 0.25)'
  }

  return 'transparent';
};

export const getSlingaDataBackgroundColor = (job: SlingaData, user: any) => {
  if (job.status === JobStatus.PAYED) {
    if (user?.userType === UserType.ADMIN || user?.userType === UserType.DRIVER_EXTENDED) {
      return 'rgba(48, 203, 131, 0.5)';
    }
    return 'hsl(328, 89%, 75%)';
  }

  if (job.status === JobStatus.INVOICED) {
    if (user?.userType === UserType.ADMIN || user?.userType === UserType.DRIVER_EXTENDED) {
      if (job.subcontractor) {
        return '#f29131';
      }

      return 'rgba(48, 203, 131, 0.5)';
    }
    return 'hsl(328, 89%, 75%)';
  }

  if (job.status === JobStatus.VALIDATED) {
    if (user?.userType === UserType.ADMIN || user?.userType === UserType.DRIVER_EXTENDED) {
      return '#ffff99';
    }
    return 'hsl(328, 89%, 75%)';
  }

  if (job.status === JobStatus.REPORTED) {
    return 'hsl(328, 89%, 75%) ';
  }
  if (job.end < Date.now()) {
    return 'hsl(345, 82%, 62%) ';
  }

  if (job.status === JobStatus.STARTED) {
    return '#9b59b680';
  }

  if (job.status === JobStatus.RECEVIED) {
    return 'rgba(82, 160, 255, 0.6)';
  }

  return 'transparent';
};

export const getJobStatusLabel = (
  job: JobDataPartial | SlingaDataPartial | JobData | SlingaData,
) => {
  if (
    job.status === JobStatus.STARTED ||
    job.status === JobStatus.RECEVIED ||
    job.status === JobStatus.NEW ||
    job.status === JobStatus.STARTED_OVERDUE ||
    job.status === JobStatus.DRAFT
  ) {
    if (job.end < Date.now() && job.status !== JobStatus.DRAFT) {
      return jobStatusLabel[JobStatus.STARTED_OVERDUE];
    }
  }

  return jobStatusLabel[job.status];
};

export function setCorrectJobStatusTextColor(job: JobDataPartial & SlingaData) {
  const jobStatusText = getJobStatusLabel(job);
  if (job.status === JobStatus.NEW) {
    if (jobStatusText === 'Förfallen') {
      return 'white';
    }
    return 'black';
  } else {
    return 'white';
  }
}
