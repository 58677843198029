import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { PopupContext } from '../contexts/PopupContext';
import { Coordinates, Place } from '../utils/types';
import { stopPropagation } from '../utils/uiHelpers';
import AlertPopup from './alert_popup/AlertPopup';
import './MapView.css';
import { ReactComponent as SearchIcon } from '../images/search.svg';

const DEFAULT_COORD = {
  lat: 57.70887,
  lng: 11.97456,
};

interface MapViewProps {
  selectedPlace?: Place;
  onUse: (place: Place) => void;
  onSaveToPlaces?: (place: Place) => void;
  onSaveToLittra?: (place: Place) => void;
}

export default function MapView({
  onUse,
  onSaveToPlaces,
  onSaveToLittra,
  selectedPlace,
}: MapViewProps) {
  const { close, showPopup } = useContext(PopupContext);

  const mapStyle: React.CSSProperties = { width: '100%', height: '50vh', borderRadius: '15px' };
  const [_map, setMap] = useState<google.maps.Map>();
  const [marker, _setMarker] = useState<google.maps.Marker>(
    new google.maps.Marker({
      position:
        selectedPlace && selectedPlace.position
          ? new google.maps.LatLng({
              lat: selectedPlace.position.lat,
              lng: selectedPlace.position.lng,
            })
          : new google.maps.LatLng(DEFAULT_COORD),
    }),
  );

  const [placesSearch, setPlacesSearch] = useState<google.maps.places.Autocomplete>();

  const [name, setName] = useState<string>(selectedPlace?.name ? selectedPlace.name : '');
  const [address, setAddress] = useState<string>(
    selectedPlace?.address ? selectedPlace.address : '',
  );
  const [coordinates, setCoordinates] = useState<Coordinates>(
    selectedPlace && selectedPlace.position
      ? {
          lat: selectedPlace.position.lat,
          lng: selectedPlace.position.lng,
        }
      : { lat: 58.68, lng: 17.06 },
  );

  useEffect(init, []);

  function init() {
    bindInputToGoogleSearch();
    initMap();
  }

  useEffect(() => {
    if (placesSearch) {
      placesSearch.addListener('place_changed', onChangeSearchPlace);
    }
  }, [placesSearch]);

  function initMap() {
    const mapEl = document.getElementById('map-view__map') as HTMLElement;
    const center: google.maps.LatLng = new google.maps.LatLng(DEFAULT_COORD);
    const map = new google.maps.Map(mapEl, {
      center,
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.HYBRID,
    });

    map.addListener('click', onClickMap);
    marker.setMap(map);
    setMap(map);
  }

  function onClickMap(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      setCoordinates({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });

      marker.setPosition(new google.maps.LatLng(lat, lng));
    }
  }

  function onChangeName(event: SyntheticEvent) {
    setName((event.target as HTMLInputElement).value);
  }

  function bindInputToGoogleSearch() {
    const inputSearch = document.getElementById(`places-search`) as HTMLInputElement;

    if (selectedPlace && selectedPlace.address) {
      inputSearch.value = selectedPlace.address;
    }

    const options = {
      language: 'se',
      componentRestrictions: { country: 'se' },
      fields: ['geometry', 'name', 'formatted_address'],
    };

    const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
      inputSearch,
      options,
    );

    setPlacesSearch(autoComplete);
  }

  function onChangeSearchPlace() {
    if (placesSearch !== undefined) {
      const placeResult = placesSearch.getPlace();
      if (!placeResult || !placeResult.geometry) {
        (document.getElementById(`places-search`) as HTMLInputElement).value = '';
      } else {
        if (placeResult && placeResult.geometry && placeResult.geometry.location) {
          marker.setPosition(
            new google.maps.LatLng(
              placeResult.geometry.location.lat(),
              placeResult.geometry.location.lng(),
            ),
          );
          marker.setTitle(
            `Vald plats lat ${placeResult.geometry.location.lat()}, lng ${placeResult.geometry.location.lng()}`,
          );

          setCoordinates({
            lat: placeResult.geometry.location.lat(),
            lng: placeResult.geometry.location.lng(),
          });

          setName(placeResult.formatted_address || '');
          // Center the map at the new location
          marker.getMap()?.setOptions({ center: placeResult.geometry.location });
        }

        if (placeResult && placeResult.formatted_address) {
          setAddress(placeResult.formatted_address);
        } else {
          setAddress('');
        }
      }
    }
  }

  function _onUse() {
    if (coordinates) {
      if (name === '') {
        showPopup(<AlertPopup message='Ange namn eller adress för platsen!' />, 'small');
      } else {
        close();
        // If we do have coordinates and a name, just use the name as address? should be ok
        if (address === '') {
          onUse({ name, address: name, position: coordinates });
        } else {
          onUse({ name, address, position: coordinates });
        }
      }
    } else {
      showPopup(<AlertPopup message='Ingen plats vald' />, 'small');
    }
  }
  function _onSaveToPlaces() {
    if (coordinates && onSaveToPlaces) {
      if (name === '' && address === '') {
        showPopup(<AlertPopup message='Ange namn eller adress för platsen!' />, 'small');
      } else {
        close();
        onSaveToPlaces({ name, address, position: coordinates });
      }
    } else {
      showPopup(<AlertPopup message='Ingen plats vald' />, 'small');
    }
  }
  function _onSaveToLittra() {
    if (coordinates && onSaveToLittra) {
      if (name === '' && address === '') {
        showPopup(<AlertPopup message='Ange namn eller adress för platsen!' />, 'small');
      } else {
        close();
        onSaveToLittra({ name, address, position: coordinates });
      }
    } else {
      showPopup(<AlertPopup message='Ingen plats vald' />, 'small');
    }
  }

  return (
    <section id='map-view' onClick={stopPropagation}>
      <section id='map-view__header__form'>
        <div className='input-icon'>
          <SearchIcon />
          <input
            id='places-search'
            type='text'
            placeholder='      Sök på plats, företagsnamn, koordinater...'
            defaultValue={address}
            className='input-text  form__li'
            style={{ width: '100%', marginBottom: '2.4rem' }}
          />
        </div>
      </section>

      <section id='map-view__map' style={mapStyle}></section>
      <p style={{ marginTop: '2.4rem', marginBottom: '1.5rem' }} className='label'>
        Namn
      </p>
      <input
        type='text'
        className='input-text form__li'
        placeholder='Ange namn'
        required
        onChange={onChangeName}
        value={name}
      />
      <section id='map-view__buttons'>
        {onSaveToLittra && (
          <button className='button--edit' style={{ width: '27%' }} onClick={_onSaveToLittra}>
            Spara på littra
          </button>
        )}
        {onSaveToPlaces && (
          <button className='button--edit' style={{ width: '27%' }} onClick={_onSaveToPlaces}>
            Spara i platser
          </button>
        )}
        <button className='button--primary' style={{ width: '40%' }} onClick={_onUse}>
          Välj plats
        </button>
      </section>
    </section>
  );
}
