import { SyntheticEvent, useEffect, useState } from 'react';
import { ChosenMaterials, MaterialData } from '../../utils/types';
import Select from '../select/Select';
import Option from '../select/Option';
import { getMaterials } from '../../firebase/firestore_functions/materials';
import { ReactComponent as SelectIcon } from '../../images/selectIcon.svg';
import { Divider } from 'antd';

interface AddMaterialsProps {
  materials: ChosenMaterials;
  setMaterials: React.Dispatch<React.SetStateAction<ChosenMaterials>>;
  materialFrom?: string;
  setMaterialFrom: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Component that is used in 'AddEditJob' and 'RowForm' to add/select materials that is used in a job/report
 */
export default function AddMaterials({
  materials,
  setMaterials,
  materialFrom,
  setMaterialFrom,
}: AddMaterialsProps) {
  const [allMaterials, setAllMaterials] = useState<MaterialData[]>([]);

  const responsableForMaterial = ['Material uttaget på Nolblads','Material uttaget på kund', 'Inget material uttaget'];

  useEffect(init, []);

  function init() {
    fetchMaterials();
  }

  async function fetchMaterials() {
    const materialsResponse = await getMaterials();
    if (materialsResponse.code === 200 && materialsResponse.data) {
      setAllMaterials(materialsResponse.data);
    }
  }

  function onChangeQuantity(event: SyntheticEvent, docId: string, idx: number) {
    const value = (event.target as HTMLInputElement).value;
    let qty;

    if (value === '') {
      qty = 0;

      // match num with 2 decimals
    } else if (/\d*\.\d{1,2}/.exec(value) && materials[docId].howMuch[idx].unit === 'Ton') {
      qty = parseFloat(value);
    } else {
      qty = parseInt(value);
    }

    const _materials = { ...materials };
    _materials[docId].howMuch[idx].qty = qty;
    setMaterials(_materials);
  }

  function onChangeMaterials(value: string) {
    const _materials = { ...materials };
    const material = allMaterials.find((mat) => mat.docId === value);

    if (material) {
      _materials[value] = {
        material,
        howMuch: [
          { qty: 0, unit: 'Lass' },
          { qty: 0, unit: 'Ton' },
        ],
      };

      setMaterials(_materials);
    }
  }

  function removeMaterial(value: string) {
    const _materials = { ...materials };
    delete _materials[value];
    setMaterials(_materials);
  }

  return (
    <li key='material' className='add-job__form__li'>
      <Select
        isMultiSelect={false}
        type='form'
        label='Material'
        onChange={onChangeMaterials}
        iconRightClose={<SelectIcon />}
        iconRightOpen={<SelectIcon />}
        showLabel={true}
        searchBar={true}
      >
        <>
          {allMaterials.map((m: MaterialData) => {
            return <Option key={m.docId} value={m.docId} label={m.description} />;
          })}
        </>
      </Select>

      {materials && Object.keys(materials).length > 0 && (
        <>
          <Divider />
          <ul className='form__selected-items'>
            {Object.keys(materials).map((m: string) => {
              return (
                <>
                  <p style={{ fontWeight: 'bold', marginTop: '2.4rem', marginBottom: '2.4rem' }}>
                    {allMaterials.find((mat) => mat.docId === m)?.description}
                  </p>
                  <p style={{ fontWeight: 'bold', marginBottom: '2.4rem' }}>
                    {'antal ' + materials[m].howMuch[0].unit}
                  </p>

                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ width: '100%' }}
                      className='input-text '
                      type='number'
                      placeholder='Antal lass'
                      value={materials[m].howMuch[0].qty || ''}
                      onChange={(event: SyntheticEvent) => {
                        onChangeQuantity(event, m, 0);
                      }}
                      step={materials[m].howMuch[0].unit === 'Ton' ? '.01' : '1'}
                      min='0'
                    />
                  </li>
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '2.4rem',
                      marginTop: '2.4rem',
                    }}
                  >
                    {materials[m].howMuch[1].unit}
                  </p>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ width: '100%' }}
                      className='input-text'
                      type='number'
                      placeholder='Antal ton'
                      value={materials[m].howMuch[1].qty || ''}
                      onChange={(event: SyntheticEvent) => {
                        onChangeQuantity(event, m, 1);
                      }}
                      step={materials[m].howMuch[1].unit === 'Ton' ? '.01' : '1'}
                      min='0'
                    />
                  </li>
                  <p style={{ fontWeight: 'bold', marginTop: '2.4rem', marginBottom: '2.4rem' }}>
                    Material uttaget på
                  </p>
                  <Select
                    isMultiSelect={false}
                    type='form'
                    label='Välj'
                    onChange={(e) => setMaterialFrom(e)}
                    iconRightClose={<SelectIcon />}
                    iconRightOpen={<SelectIcon />}
                    searchBar={true}
                  >
                    <>
                      {responsableForMaterial.map((m: string) => {
                        return <Option key={m} value={m} label={m} />;
                      })}
                    </>
                  </Select>

                  <button
                    style={{ marginTop: '2.4rem' }}
                    className='button--edit'
                    onClick={() => {
                      removeMaterial(m);
                    }}
                  >
                    Ta bort material
                  </button>
                </>
              );
            })}
          </ul>
          <Divider />
        </>
      )}
    </li>
  );
}
