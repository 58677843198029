import React from 'react';
import { ReactComponent as DragAndDropIcon } from '../../images/dragAndDrop.svg';

interface Props {
  text: string;
  onClick: () => void;
  icon?: React.ReactElement;
  backgroundColor?: string;
}

export default function DragAndDrop(props: Props) {
  return (
    <div className='dragAndDrop' onClick={props.onClick}>
      <div className='dragAndDrop-inner'>
        <div style={{ marginRight: '10px' }}>
          <DragAndDropIcon />
        </div>
        {props.text}
      </div>
    </div>
  );
}
