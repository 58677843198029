import { SyntheticEvent, useEffect, useRef } from 'react';

type TextareaProps = {
  value: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  placeholder: string;
  label?: string;
  disabled?: boolean;
};

/**
 *
 * Wrapper around textarea to not duplicate onChange and resize functions
 * @returns
 */
export default function Textarea({
  id,
  value,
  setState,
  placeholder,
  label,
  disabled,
}: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  /**
   * Resizes height of textarea
   * @param targetTextarea
   */
  function resizeTextarea(targetTextarea: HTMLTextAreaElement) {
    targetTextarea.style.height = '1em';
    let newHeight = +targetTextarea.scrollHeight + 2;
    if (newHeight < 100) {
      newHeight = 100;
    }
    targetTextarea.style.height = newHeight + 'px';
  }

  function onChange(event: SyntheticEvent) {
    setState((event.target as HTMLInputElement).value);
  }

  // useEffect to resize textarea on initial render
  useEffect(() => {
    if (textareaRef.current) {
      resizeTextarea(textareaRef.current);
    }
  }, [value]); // Trigger on value change

  return (
    <>
      {label && (
        <label className='label' style={{ padding: '2px' }}>
          {label}
        </label>
      )}
      <textarea
        ref={textareaRef}
        id={id}
        className='textarea'
        onChange={onChange}
        onInput={onChange} // Use onChange to resize on input change as well
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    </>
  );
}
