import { useContext, useState } from 'react';
import { ClientData, LittraData, Littra } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import LittraForm from '../forms/LittraForm';
import { addLittra, toLittraDoc } from '../firebase/firestore_functions/clients';

interface AddLittraProps {
  client: ClientData;
  onAdd?: (littra: LittraData) => void;
  job?: any;
}

export default function AddLittra({ client, onAdd, job }: AddLittraProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  async function handleSubmit(littra: Littra) {
    setSaving(true);
    const littraDoc = toLittraDoc(littra, client.docId);
    const addResponse = await addLittra(littraDoc, client.docId);
    if (addResponse.code === 201 && addResponse.data) {
      // if onAdd (AddJob needs the new littra to add it to the job)
      if (onAdd) {
        let littraData: LittraData;
        if (littraDoc.contact) {
          const contact = client.contacts.find((c) => c.docId === littraDoc.contact?.id);

          littraData = {
            ...littraDoc,
            contact,
            docId: addResponse.data,
          };
        } else {
          littraData = {
            ...littraDoc,
            contact: undefined,
            docId: addResponse.data,
          };
        }

        onAdd(littraData);
      }
      setSaving(false);
      notify('Littra tillagt', NotificationStatus.SUCCESS);
      close();
    } else {
      setSaving(false);
      notify(`Kunde inte spara littra: ${addResponse.error}`, NotificationStatus.ERROR);
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Lägg till ny littra</h2>
      </header>
      <main>
        <LittraForm handleSubmit={handleSubmit} saving={saving} client={client} job={job} />
      </main>
    </section>
  );
}
