import { isToday, toDayDateString } from '../../../utils/time';
import { JobDataPartial, SlingaData } from '../../../utils/types';
import './WeekdayDesktop.css';
import { WeekDayEntry } from '../TableComponents/WeekDayEntry';

interface WeekDayProps {
  dateMillis: number | string;
  jobs: Array<JobDataPartial & SlingaData>;
  onClickJob: (docId: string, clickedDate?: number) => void;
  onClickSlinga: (slinga: string) => void;
}

function WeekDayDesktop({ dateMillis, jobs, onClickJob, onClickSlinga }: WeekDayProps) {
  function areAllJobsCompleted(jobs: Array<JobDataPartial & SlingaData>): boolean {
    if (jobs.length === 0) return false;
    return jobs.every((job) => job.status === 6);
  }
  console.log(jobs);
  const isToday = (date: number) => {
    const today = new Date();
    const currentDate = new Date(date);
    return (
      currentDate.getDate() === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  };

  const colorString = isToday(dateMillis)
    ? 'date-container-red'
    : areAllJobsCompleted(jobs)
    ? 'date-container-green'
    : 'date-container-white';

  return (
    <>
      <tr className={`date-container ${colorString}`}>
        <td colSpan={7} style={{ padding: '0px' }}>
          <p className='date-container-date'>
            {typeof dateMillis === 'string' ? dateMillis : toDayDateString(dateMillis)}
          </p>
          <p className='date-container-job-ammount'>{`${Object.values(jobs).length} jobb`}</p>
        </td>
      </tr>
      {jobs && Object.values(jobs)?.length === 0 && (
        <tr>
          <td id='no-jobs' colSpan={7}>
            Inga jobb
          </td>
        </tr>
      )}
      {jobs.map((job: JobDataPartial & SlingaData) => {
        return (
          <WeekDayEntry
            key={job.docId}
            job={job}
            onClickJob={onClickJob}
            onClickSlinga={onClickSlinga}
            dateMillis={dateMillis}
          />
        );
      })}
    </>
  );
}

export default WeekDayDesktop;
