import Select from '../select/Select';
import Option from '../select/Option';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ClientData, ContactData, ContactDoc } from '../../utils/types';
import { PopupContext } from '../../contexts/PopupContext';
import AddContact from '../../popups/AddContact';
import { addContact } from '../../firebase/firestore_functions/contacts';
import { ReactComponent as SelectIcon } from '../../images/selectIcon.svg';
import { ReactComponent as DeleteIcon } from '../../images/deleteIcon.svg';

interface AddContactClientProps {
  client?: ClientData;
  contactClient?: ContactData;
  contactClientTemp?: ContactData | ContactData[];
  setContactClient: React.Dispatch<React.SetStateAction<ContactData | undefined>>;
  setContactClientTemp: React.Dispatch<React.SetStateAction<ContactData[]>>;
}

/**
 * Component that is used in 'AddEditJob* to add/select a contact to the chosen client
 */
export default function AddContactClient({
  client,
  contactClient,
  contactClientTemp,
  setContactClient,
  setContactClientTemp,
}: AddContactClientProps) {
  const { showPopup } = useContext(PopupContext);

  const [contactClients, setClientContacts] = useState<ContactData[]>([]);
  const [selectComponents, setSelectComponents] = useState<number[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<ContactData[]>([]);

  const addNewSelect = () => {
    setSelectComponents([...selectComponents, selectComponents.length + 1]);
  };

  const onChangeContactClient = (index: number, value: string) => {
    const _contact = contactClients.find((c) => c.docId === value);
    if (_contact) {
      const newSelectedContacts = [...selectedContacts];
      newSelectedContacts[index] = _contact;
      setSelectedContacts(newSelectedContacts);
      if (newSelectedContacts) setContactClientTemp(newSelectedContacts);
    }
  };

  useEffect(updateClientContacts, [client]);

  function updateClientContacts() {
    setClientContacts(client && client.contacts ? client.contacts : []);
  }

  async function onAddContact(contact: ContactDoc) {
    if (client) {
      const response = await addContact(contact, client, 'clients');

      if (response.data) {
        const contactData = { ...contact, docId: response.data };
        const _contacts = [...contactClients, contactData];
        setClientContacts(_contacts);
        setContactClient(contactData);
      }

      return response;
    } else {
      return { code: 500, error: 'no client' };
    }
  }

  // function onChangeContactClient(value: string) {
  //   const _contact = contactClients.find((c) => c.docId === value);
  //   if (_contact) {
  //     setContactClientTemp('');
  //     setContactClient(_contact);
  //   }
  // }

  // function onChangeContactClientTemp(event: SyntheticEvent) {
  //   setContactClient(undefined);
  //   setContactClientTemp((event.target as HTMLInputElement).value);
  // }

  function openAddClientContact(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddContact onAdd={onAddContact} />, 'medium');
  }

  function deleteContact(key: number) {
    setSelectedContacts(selectedContacts.filter((c, idx) => idx !== key));
    setContactClientTemp(selectedContacts.filter((c, idx) => idx !== key));
    setSelectComponents((prevComponents) => {
      const updatedComponents = prevComponents.filter((c) => c !== key);
      return updatedComponents.map((_, index) => index + 1);
    });
  }

  return (
    <li key='contact' className='add-job__form__li' id='add-job__form__li-contact'>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <p style={{ marginBottom: '15px', marginTop: '15px' }} className='label'>
          Kontakt
        </p>
        {/* <input
          id='contact'
          type='text'
          className='input-text add-job__form__input'
          value={contactClientTemp}
          placeholder='Tillfällig kontakt'
          autoComplete='off'
          onChange={onChangeContactClientTemp}
        />
        {contactClientTemp && contactClientTemp !== '' && (
          <button
            onClick={() => setContactClientTemp('')}
            style={{ position: 'absolute', right: '2rem' }}
            className='fa fa-times'
          ></button>
        )} */}
      </div>
      <Select
        key={0}
        isMultiSelect={false}
        searchBar={true}
        type='form'
        label='Kontakt'
        onChange={(selectedOption) => onChangeContactClient(0, selectedOption)}
        iconRightClose={<SelectIcon />}
        iconRightOpen={<SelectIcon />}
        initialOption={
          selectedContacts[0]
            ? {
                value: selectedContacts[0]?.docId,
                label: selectedContacts[0]?.name,
              }
            : undefined
        }
      >
        <>
          {contactClients.map((c: ContactData, idx: number) => {
            return <Option key={idx} value={c.docId} label={c.name} />;
          })}
        </>
      </Select>
      <div style={{ marginBottom: '10px' }} />
      {selectComponents.map((key) => (
        <>
          {key > 0 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                key={key}
                isMultiSelect={false}
                searchBar={true}
                type='form'
                label='Kontakt'
                onChange={(selectedOption) => onChangeContactClient(key, selectedOption)}
                iconRightClose={<SelectIcon />}
                iconRightOpen={<SelectIcon />}
                initialOption={
                  selectedContacts[key]
                    ? {
                        value: selectedContacts[key]?.docId,
                        label: selectedContacts[key]?.name,
                      }
                    : undefined
                }
              >
                <>
                  {contactClients.map((c: ContactData, idx: number) => {
                    return <Option key={idx} value={c.docId} label={c.name} />;
                  })}
                </>
              </Select>
              <></>
              <button onClick={() => deleteContact(key)}>
                <DeleteIcon
                  style={{ cursor: 'pointer' }} // Ensure it's clickable and on top
                />
              </button>
            </div>
          )}
          <div style={{ marginBottom: '10px' }} />
        </>
      ))}
      <button
        id='button--add-contact'
        className='button-add-kontakt'
        onClick={openAddClientContact}
      >
        <span className='fa fa-plus' />
        Skapa ny kontakter
      </button>
      <button id='button--add-contact' className='button-add-kontakt' onClick={addNewSelect}>
        <span className='fa fa-plus' />
        Lägg till fler kontakter
      </button>
    </li>
  );
}
