import { useContext } from 'react';
import AlertPopup from '../../components/alert_popup/AlertPopup';
import { AuthContext } from '../../contexts/AuthContext';
import { MobileStateContext } from '../../contexts/MobileContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import { populateFieldsInJobDoc, updateJob } from '../../firebase/firestore_functions/job';
import { JobStatus, NotificationStatus, UserType } from '../../utils/constants';
import { canStartJob } from '../../utils/jobUtils';
import { JobData } from '../../utils/types';
import ReportJob from '../report_job';
import ReportJobFinal from '../report_job/ReportJobFinal';
import Sign from '../sign_job/Sign';
import PopupButton from '../PopupButton';
import { Check, PenSquare, Pencil } from 'lucide-react';

type Props = {
  details: JobData;
  calendarClickedDate?: number;
};

/**
 * Includes all the controls which a driver can use to change the job
 */
export default function DriverControls({ details, calendarClickedDate }: Props) {
  const { user } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);
  const { showPopup } = useContext(PopupContext);
  const { isMobile } = useContext(MobileStateContext);

  async function startJob() {
    // TODO What happends if details.driver? is undefined? Should the driver just become the signed in user?
    // Ask Johanna
    if (details) {
      if (!canStartJob(user, details.driver, details.vehicle)) {
        showPopup(<AlertPopup message='Du kan ej starta detta jobb!' />, 'small');
        return;
      }
      const updateStatusResponse = await updateJob(details.docId, {
        status: JobStatus.STARTED,
        workStarted: new Date().getTime(),
      });

      if (updateStatusResponse.code === 201) {
        notify('Jobbet har startats', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Jobbet kunde inte startas: ${updateStatusResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }
  function openReportJob() {
    details &&
      showPopup(
        <ReportJob
          job={details}
          initialDate={calendarClickedDate !== undefined ? calendarClickedDate : details.start}
        />,
        'medium',
      );
  }

  function openReportJobFinal() {
    details && showPopup(<ReportJobFinal job={details} />, 'medium');
  }

  function openSign() {
    details && showPopup(<Sign job={details} />, 'medium');
  }

  if (user && [UserType.DRIVER_EXTENDED, UserType.DRIVER, UserType.ADMIN].includes(user.userType)) {
    return (
      <>
        {/* CAN START THE JOB IF IT IS NEW AND NOT STARTED */}
        {details.status < JobStatus.STARTED && (
          <PopupButton text='Starta' onClick={startJob} backgroundColor='var(--primary-color)' />
        )}

        {/* CAN SIGN JOB IF THE DEVICE IS MOBILE AND THE JOB ATLEAST HAS BEEN STARTED */}
        {[
          JobStatus.STARTED,
          JobStatus.STARTED_OVERDUE,
          JobStatus.REPORTED,
          JobStatus.VALIDATED,
          JobStatus.PAYED,
        ].includes(details.status) &&
          isMobile &&
          !details.signed && (
            <PopupButton
              text='Signera'
              onClick={openSign}
              backgroundColor='var(--primary-color)'
              icon={<Pencil size={16} strokeWidth={3} />}
            />
          )}

        {/* CAN REPORT ON JOB IF THE LOGGED IN DRIVER IS THE DRIVER OF THE JOB AND THE JOB HAS NOT BEEN REPORTED AS DONE YET */}
        {/* Or if the user is an admin */}
        {user &&
          ([UserType.ADMIN].includes(user.userType) ||
            canStartJob(user, details.driver, details.vehicle)) &&
          details.status < JobStatus.REPORTED &&
          details.status >= JobStatus.STARTED && (
            <>
              <PopupButton
                text='Dagsrapportera'
                onClick={openReportJob}
                backgroundColor='var(--primary-color)'
                icon={<PenSquare size={16} strokeWidth={3} />}
              />

              <PopupButton
                text='Slutrapportera'
                onClick={openReportJobFinal}
                backgroundColor='var(--success-color)'
                icon={<Check size={16} strokeWidth={3} />}
              />
            </>
          )}
      </>
    );
  } else {
    return null;
  }
}
