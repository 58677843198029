import { BlobProvider, Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { companyAddress, companyMail, companyPhone, JobType } from '../../utils/constants';
import { getJobSignature, getLittraText, getLittraWorkText } from '../../utils/jobUtils';
import { JobData } from '../../utils/types';
import OrderRow from './OrderRow';
import styles from './PDFStyles';
import { toRangeDateString } from '../../utils/time';

export default function PdfButton(props: {
  jobs: JobData[];
  includeLogo: boolean;
  includePictures?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
      <BlobProvider
        document={
          <Pdf
            jobs={props.jobs}
            includeLogo={props.includeLogo}
            includePictures={props.includePictures}
          />
        }
        key={props.jobs.length}
      >
        {({ url }) => {
          return (
            <a
              href={url || ''}
              style={{
                color: '#E5097F',
                display: 'flex',
                textDecoration: 'none',
              }}
              target='_blank'
              rel='noreferrer'
            >
              {props.children}
            </a>
          );
        }}
      </BlobProvider>
    </div>
  );
}

function Pdf(props: { jobs: JobData[]; includeLogo: boolean; includePictures?: boolean }) {
  const jobOrderNumbers = () => {
    let str = '';
    props.jobs.forEach((job) => {
      str += `${job.orderNum}, `;
    });

    // remove last comma
    str = str.substring(0, str.length - 2);
    return str;
  };

  return (
    <Document>
      {props.jobs.map((job, idx) => {
        return (
          <Page size='A4' key={job.docId} style={styles.page} wrap>
            {idx === 0 && props.includeLogo && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 70,
                }}
              >
                <View style={{ ...styles.top, flex: 1, height: '150px' }}>
                  <Image src='/images/logga.png' style={{ width: 165 }} />
                  <Text style={styles.tableBody}>{companyAddress}</Text>
                  <Text style={styles.tableBody}>{companyPhone}</Text>
                  <Text style={styles.tableBody}>{companyMail}</Text>
                </View>
                <View
                  style={{
                    ...styles.top,
                    flex: 1,
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={styles.body}>Transportsedel</Text>
                  <Text style={styles.body}>{jobOrderNumbers()}</Text>
                </View>
              </View>
            )}

            <Text
              style={{
                ...styles.body,
                borderBottom: '1px solid black',
                paddingBottom: '12px',
                marginTop: '30pt',
              }}
            >
              {job.orderNum && `Ordernummer ${job.orderNum}`}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
                height: '300px',
              }}
            >
              <Text
                style={{
                  ...styles.body,
                  marginBottom: '3px',
                }}
              >{`Kund ${
                job.contactClientTemp ? job.contactClientTemp[0]?.name : job.contactClient?.name
              }`}</Text>
              <Text
                style={{
                  ...styles.body,
                  marginBottom: '3px',
                }}
              >{`Utförare ${job?.driver?.firstName + ' ' + job?.driver?.lastName || ''}`}</Text>
              <Text
                style={{
                  ...styles.body,
                  marginBottom: '3px',
                }}
              >{`Arbetsplats ${getLittraWorkText(job) || '-'}`}</Text>
              <Text
                style={{
                  ...styles.body,
                  marginBottom: '3px',
                }}
              >{`Littra ${getLittraText(job) || '-'}`}</Text>

              {job.jobType === JobType.FACILITY && (
                <View
                  style={{
                    ...styles.tableHeader,
                    width: '100%',
                    marginTop: '20px',
                    maxHeight: '40px',
                    minHeight: '30px',
                  }}
                >
                  <View style={{ ...styles.tableCell, width: '15%' }}>
                    <Text style={{ ...styles.tableTitle, color: '#ffffff' }}>Datum</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '15%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Artikelnr</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Tjänst</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '10%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Antal</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '10%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Enhet</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '30%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Kommentar</Text>
                  </View>
                </View>
              )}
              {job.jobType === JobType.TRANSPORT && (
                <View
                  style={{
                    ...styles.tableHeader,
                    width: '100%',
                    marginTop: '20px',
                    maxHeight: '40px',
                    minHeight: '30px',
                  }}
                >
                  <View style={{ ...styles.tableCell, width: '15%' }}>
                    <Text style={{ ...styles.tableTitle, color: '#ffffff' }}>Artikelnr</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '15%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Tjänst/gods</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Från - Till</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '10%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Antal</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '10%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Enhet</Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '30%' }}>
                    <Text style={{ ...styles.tableTitle, color: 'white' }}>Uttaget på</Text>
                  </View>
                </View>
              )}
            </View>
            <OrderRow job={job} />
          </Page>
        );
      })}

      {/* Images */}
      {props.jobs.map((job) => {
        return (
          props.includePictures &&
          job?.reports &&
          job?.reports.map((report) => {
            // Check if the report has images
            if (report.images && report.images.length > 0) {
              return (
                <Page size='A4' key={`${job.docId}-${report.start}`} style={styles.page} wrap>
                  <Text
                    style={{
                      ...styles.body,
                      borderBottom: '1px solid lightgrey',
                      padding: '6 0',
                    }}
                  >
                    {toRangeDateString(report.start, report.end).split(' ')[0]}({job.orderNum})
                  </Text>
                  {report.images.map((img, idx) => {
                    return <Image key={`${img}-${idx}`} style={{ marginTop: 6 }} src={img} />;
                  })}
                </Page>
              );
            }
            // If no images on the report
            return null;
          })
        );
      })}
    </Document>
  );
}
