import { SyntheticEvent, useContext, useEffect, useState } from 'react';

import {
  AddedMaterialData,
  ChosenMaterials,
  IRoute,
  JobData,
  JobDoc,
  LittraData,
  Place,
  UpdateJobParams,
  UpdateJobParamsData,
  User,
  VehicleData,
} from '../../utils/types';

import Option from '../../components/select/Option';
import Select from '../../components/select/Select';
import {
  JobStatus,
  jobStatusLabel,
  JobType,
  jobTypeLabel,
  NotificationStatus,
  UserType,
  vehicleNotAssigned,
  VehicleType,
  vehicleTypeLabel,
} from '../../utils/constants';
import './AddEditJob.css';

import Datepicker from '../../components/datepicker/Datepicker';
import Timepicker from '../../components/timepicker/Timepicker';
import { NotificationContext } from '../../contexts/NotificationContext';
import { toDayDateString } from '../../utils/time';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PickAPlace from '../../components/PickAPlace';
import Textarea from '../../components/Textarea';
import { PopupContext } from '../../contexts/PopupContext';
import { fromJobDataToJobDoc, updateJob } from '../../firebase/firestore_functions/job';
import AddEquipments from './AddEquipmentsToJob';
import AddMaterials from './AddMaterials';

import { addJob, deleteJob } from '../../firebase/firestore_functions/job';

import AlertPopup from '../../components/alert_popup/AlertPopup';
import AddImages from '../../components/components_for_add_edit_job_slinga/AddImages';
import ConfirmAction from '../../components/confirm_action';
import { DataContext } from '../../contexts/CommonDataContext';
import { updateLittra } from '../../firebase/firestore_functions/clients';
import { deleteImageStorage, getImageUrl, saveImageStorage } from '../../firebase/storageFunctions';
import AddClient from './AddClientToJob';
import AddContactClient from './AddContactClientJob';
import AddContactSubcontractor from './AddContactSubcontractorToJob';
import { AddEditJobContext } from './AddEditJobContext';
import AddLittra from './AddLittra';
import AddSubcontractor from './AddSubcontractorToJob';

import { v4 as uuidv4 } from 'uuid';
import { b64ToBlob } from '../../utils/otherHelpers';
import { AuthContext } from '../../contexts/AuthContext';
import JobDetailsDivider from '../job_details/JobDetailsDivider';
import { ReactComponent as SelectIcon } from '../../images/selectIcon.svg';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import { ReactComponent as VectorRight } from '../../images/vectorRight.svg';
import { ReactComponent as VectorLeft } from '../../images/vectorLeft.svg';
import { Divider } from 'antd';
import { Switch } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { Radio, Space } from 'antd';
import AddLittraPopup from '../AddLittra';
import React from 'react';

interface AddJobProps {
  job?: JobData; // for edit mode
  onDeleteJob?: () => void;
}

function AddJob({ job, onDeleteJob }: AddJobProps) {
  const { notify } = useContext(NotificationContext);
  const { showPopup, close } = useContext(PopupContext);

  const [initDone, setInitDone] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  const { drivers, vehicles } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  const distanceService = new google.maps.DistanceMatrixService();
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  // data states for the job
  const {
    jobType,
    setJobType,
    status,
    setStatus,
    // from,
    // setFrom,
    // to,
    // setTo,
    estimatedDistance,
    estimatedTime,
    setEstimatedDistance,
    setEstimatedTime,
    start,
    setStart,
    end,
    setEnd,
    vehicle,
    setVehicle,
    vehicleEquipments,
    driver,
    setDriver,
    client,
    contactClient,
    contactClientTemp,
    littra,
    littraTemp,
    littraWorkplaceTemp,
    subcontractor,
    contactSubcontractor,
    materials,
    setMaterials,
    images,
    setImages,
    setLittra,
    otherInformation,
    setOtherInformation,
    otherMaterials,
    setOtherMaterials,
    adminComments,
    setAdminComments,
    updateStateEditMode,
    updateRoutes,
    editRoutes,
    routes,
    materialFrom,
    setMaterialFrom,
  } = useContext(AddEditJobContext);

  const [saving, setSaving] = useState<boolean>(false);
  const [littras, setLittras] = useState<LittraData[]>([]);
  const [showHours, setShowHours] = useState<boolean>(false);
  const [korning, setKorning] = useState(1);
  const [count, setCount] = useState(1);

  const [from, setFrom] = useState<Place | undefined>();
  const [to, setTo] = useState<Place | undefined>();
  const [godsslag, setGodsslag] = useState<string>('');

  const isLej = [VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
    vehicle.vehicleType,
  );

  //////////////////
  // USE EFFECTS //
  /////////////////

  useEffect(init, []);

  useEffect(getDistance, [from, to]);

  function init() {
    if (job) {
      updateStateEditMode(job);
      setEditMode(true);
      setInitDone(true);
    } else {
      setInitDone(true);
    }
  }

  ////////////////////////
  // ON CHANGE HANDLERS //
  ////////////////////////

  const addAndSaveRoute = () => {
    const material = Object.keys(materials).length > 0 ? materials : godsslag;
    updateRoutes([
      { index: routes?.length || 0, from, to, materials: material, materialFrom: materialFrom },
    ]);
    setFrom(undefined);
    setTo(undefined);
    setMaterials({});
    setGodsslag('');
    setCount(count + 1);
    setMaterialFrom('');
  };

  const changeKorning = (e: RadioChangeEvent) => {
    setKorning(e.target.value);
  };

  function onChangeJobType(value: string) {
    setJobType(parseInt(value));
  }

  function onChangeStatus(value: string) {
    setStatus(parseInt(value));
  }

  console.log(vehicleEquipments);
  function onChangeVehicle(value: string) {
    // update vehicle
    const _vehicle = vehicles.find((v) => v.docId === value);

    if (_vehicle) {
      setVehicle(_vehicle);

      // set default driver
      if (_vehicle.driver) {
        setDriver(_vehicle.driver);
      } else {
        // reset
        setDriver(undefined);
      }
    } else {
      // reset
      setVehicle(vehicleNotAssigned);
    }
  }

  function onChangeDriver(value: string) {
    const _driver = drivers.find((d) => d.docId === value);
    if (_driver) {
      setDriver(_driver);
    } else {
      setDriver(undefined);
    }
  }

  function onChangeDateFrom(date: number) {
    const _date = new Date(date);
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    if (startDate) {
      _date.setHours(startDate.getHours());
      _date.setMinutes(startDate.getMinutes());
    }
    setStart(_date.getTime());

    if (endDate && _date.getTime() > endDate.getTime()) {
      // Update the To-date if From-date is later than it
      _date.setHours(endDate.getHours());
      _date.setMinutes(endDate.getMinutes());
      setEnd(_date.getTime());
    }
  }

  function onChangeDateTo(date: number) {
    const _date = new Date(date);
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    if (endDate) {
      _date.setHours(endDate.getHours());
      _date.setMinutes(endDate.getMinutes());
    }
    setEnd(_date.getTime());

    if (startDate && _date.getTime() < startDate.getTime()) {
      // Update the From-date if To-date is earlier than it
      _date.setHours(startDate.getHours());
      _date.setMinutes(startDate.getMinutes());
      setStart(_date.getTime());
    }
  }

  function onAddLittra(littra: LittraData) {
    const _littras = [...littras, littra];
    setLittras(_littras);
    setLittra(littra);
  }

  function openAddLittra(event: SyntheticEvent) {
    event.preventDefault();
    client &&
      showPopup(
        <AddLittraPopup client={client} job={{ from: from, to: to }} onAdd={onAddLittra} />,
        'medium',
      );
  }

  function onChangeHoursFrom(hours: number) {
    if (start) {
      const _date = new Date(start);
      _date.setHours(hours);
      setStart(_date.getTime());
    }
  }

  function onChangeMinutesFrom(minutes: number) {
    if (start) {
      const _date = new Date(start);
      _date.setMinutes(minutes);
      setStart(_date.getTime());
    }
  }

  function onChangeHoursTo(hours: number) {
    if (end) {
      const _date = new Date(end);
      _date.setHours(hours);
      setEnd(_date.getTime());
    }
  }

  function onChangeMinutesTo(minutes: number) {
    if (end) {
      const _date = new Date(end);
      _date.setMinutes(minutes);
      setEnd(_date.getTime());
    }
  }

  function swapPlaces() {
    if (to && from) {
      setFrom(to);
      setTo(from);
    }
  }

  async function getUpdates() {
    const updates: UpdateJobParamsData = {
      vehicleEquipments: Array.from(vehicleEquipments).map((e) => {
        return {
          id: e,
          docId: e,
          equipmentType: 0,
          service: '',
          serviceId: '',
        };
      }),
    };
    if (job) {
      if (materials) {
        updates.materials = Object.values(materials);
      }

      if (start !== job.start || end !== job.end) {
        updates.start = start;
        updates.end = end;
      }

      if (status !== job.status) {
        updates.status = status;
      }
      if (to?.name !== job.to?.name) {
        updates.to = to;
      }

      if (from?.name !== job.from?.name) {
        updates.from = from;
      }
      if (otherMaterials !== job.otherMaterials) {
        updates.otherMaterials = otherMaterials;
      }
      if (otherInformation !== job.otherInformation) {
        updates.otherInformation = otherInformation;
      }
      if (adminComments !== job.adminComments) {
        updates.adminComments = adminComments;
      }
      if (driver?.docId !== job.driver?.docId) {
        updates.driver = driver;
      }
      if (vehicle.docId !== job.vehicle?.docId) {
        updates.vehicle = vehicle;
      }
      /*       if (client?.docId === job.client?.docId) { 
        updates.client = client;
        } */
      /*
       * NOTE (ps): Other fields may depend on the client so we always want to update it (e.g littras are stored in client).
       * May not be the most optimal solution, but it works for now.
       */
      updates.client = client;

      if (contactClient && contactClient?.docId !== job.contactClient?.docId) {
        updates.contactClient = contactClient;
      }
      if (contactClientTemp !== job.contactClientTemp) {
        updates.contactClientTemp = contactClientTemp;
      }
      if (subcontractor?.docId !== job.subcontractor?.docId) {
        updates.subcontractor = subcontractor;
      }
      if (contactSubcontractor?.docId !== contactSubcontractor) {
        updates.contactSubcontractor = contactSubcontractor;
      }
      if (littra && littra?.docId !== job.littra?.docId) {
        updates.littra = littra;
      }
      if (littraTemp !== job.littraTemp) {
        updates.littraTemp = littraTemp;
      }
      if (littraWorkplaceTemp !== job.littraWorkplaceTemp) {
        updates.littraWorkplaceTemp = littraTemp;
      }
      if (estimatedDistance?.meters !== job.estimatedDistance?.meters) {
        updates.estimatedDistance = estimatedDistance;
      }
      if (estimatedTime?.seconds !== job.estimatedTime?.seconds) {
        updates.estimatedTime = estimatedTime;
      }

      updates.images = await updateImagesInStorage();

      updates.estimatedDistance = undefined;
    }
    return updates;
  }

  async function updateImagesInStorage() {
    const updatedImages: string[] = [];

    if (job) {
      const promises: Promise<any>[] = [];

      for (const img of images) {
        // if added image
        if (!job.images || !job.images.includes(img)) {
          promises.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise<void>(async (resolve, _) => {
              // save image
              const imageId = uuidv4();
              await saveImageStorage(`/${job.orderNum}/from_office/${imageId}`, b64ToBlob(img));

              // get the new url
              const urlResponse = await getImageUrl(`/${job.orderNum}/from_office/${imageId}`);

              if (urlResponse.data) {
                updatedImages.push(urlResponse.data);
              }

              resolve();
            }),
          );
        } else {
          updatedImages.push(img);
        }
      }

      for (const img of deletedImages) {
        promises.push(deleteImageStorage(img));
      }

      await Promise.all(promises);
    }
    return updatedImages;
  }

  async function save() {
    // client and place is required
    //addAndSaveRoute();
    // if (!client) {
    //   showAlertNoClient();
    //   return;
    // }

    if (job) {
      setSaving(true);
      const updates = await getUpdates();
      await saveUpdatedJob(job.docId, updates);
    } else {
      setSaving(true);
      const newJobData: JobData = {
        jobType,
        orderNum: 1,
        docId: '1', // fake for now since required in jobData,
        start,
        end,
        status,
        otherMaterials,
        hasReceiptsForCompany: false,
        adminComments,
        littraTemp: littraTemp,
        littraWorkplaceTemp: littraWorkplaceTemp,
        from,
        to,
        vehicle,
        routes: routes,
        vehicleEquipments: Array.from(vehicleEquipments).map((e) => {
          return {
            id: e,
            docId: e,
            equipmentType: 0,
            service: '',
            serviceId: '',
          };
        }),
        // materials: Object.values(materials),
        littra,
        otherInformation,
        contactClientTemp,
        images,
        smsHasBeenSent: false,
      };

      if (driver) newJobData.driver = driver;
      if (client) newJobData.client = client;
      if (subcontractor) newJobData.subcontractor = subcontractor;
      if (contactClient) newJobData.contactClient = contactClient;
      if (contactSubcontractor) newJobData.contactSubcontractor = contactSubcontractor;
      if (estimatedTime) newJobData.estimatedTime = estimatedTime;
      if (estimatedDistance) newJobData.estimatedDistance = estimatedDistance;
      addNewJob(fromJobDataToJobDoc(newJobData));
    }
  }

  function showAlertNoClient() {
    showPopup(<AlertPopup message='Du har inte lagt till kund!' />, 'small');
  }

  function showAlertNoLittra() {
    showPopup(
      <AlertPopup message='Du måste lägga till littra och kund för att kunna spara platsen på littrat!' />,
      'small',
    );
  }

  async function saveUpdatedJob(docId: string, updates: UpdateJobParams) {
    let updateResponse;

    if (updates.start || updates.end) {
      // Why do we have different update responses?
      updateResponse = await updateJob(docId, updates, job?.start, job?.end);
    } else {
      updateResponse = await updateJob(docId, updates);
    }

    setSaving(false);

    if (updateResponse.code === 201) {
      // send sms to driver
      let link;
      let phone;
      if (
        [VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
          vehicle.vehicleType,
        )
      ) {
        if (subcontractor) {
          if (contactSubcontractor) {
            link = `Hej ${contactSubcontractor.name}!\n\nDu har ett uppdaterat uppdrag från Nolblad:\nhttps://nolblad.se/job-${job?.docId}`;
            phone = contactSubcontractor.phone;
          }
        }
      } else if (driver) {
        phone = driver.phone;
        link = `Hej ${driver.firstName}!\n\nDu har ett uppdaterat uppdrag från Nolblad:\nhttps://nolblad.se/`;
      }

      close();
      notify('Jobb uppdaterat', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte uppdatera jobbet, ${updateResponse.error}`, NotificationStatus.ERROR);
    }
  }

  async function addNewJob(jobDoc: JobDoc) {
    const addJobResponse = await addJob(jobDoc);

    console.log(addJobResponse);

    if (addJobResponse.code === 200 && addJobResponse.data) {
      setSaving(false);
      close();
      notify(
        `Jobb med ordernummer ${addJobResponse.data.orderNumber} skapat`,
        NotificationStatus.SUCCESS,
      );
    } else {
      notify(`Kunde inte lägga till jobbet, ${addJobResponse.error} `, NotificationStatus.ERROR);
    }
  }

  function confirmDelete() {
    showPopup(
      <ConfirmAction
        confirm={_deleteJob}
        heading='Är du säker på det här?'
        message='Om jobbet tas bort från systemet kan det inte återställas senare.'
      />,
      'small',
    );
  }

  async function _deleteJob() {
    if (job) {
      const deleteResponse = await deleteJob(job);

      if (deleteResponse.code === 200) {
        notify('Jobb raderat', NotificationStatus.SUCCESS);
        close();

        // on delete callback so that the previous popup component can close itself if wished
        onDeleteJob && onDeleteJob();
      } else {
        notify('Jobbet kunde inte tas bort', NotificationStatus.ERROR);
      }
    }
  }

  async function onSavePlaceToLittra(place: Place) {
    if (littra && client) {
      const updateResponse = await updateLittra(
        littra.places ? { places: [...littra.places, place] } : { places: [place] },
        littra?.docId,
        client.docId,
      );

      if (updateResponse.code === 201) {
        notify('Sparat plats på littra', NotificationStatus.SUCCESS);
      } else {
        notify('Kunde inte spara plats på littra', NotificationStatus.ERROR);
      }
    } else {
      showAlertNoLittra();
    }
  }

  /*
   * Get initial driver option:
   * If the user is an extended driver, he is only able to
   * select his own name.
   */
  const getInitialDriverOption = () => {
    if (user?.userType == UserType.DRIVER_EXTENDED) {
      setDriver(user);
      return {
        value: user.docId,
        label: `${user.firstName} ${user.lastName}`,
      };
    } else if (driver) {
      return {
        value: driver.docId,
        label: `${driver.firstName} ${driver.lastName}`,
      };
    } else {
      return undefined;
    }
  };

  function getDistance() {
    if (from && to && from.position && to.position) {
      distanceService.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(from.position.lat, from.position.lng)],
          destinations: [new google.maps.LatLng(to.position.lat, to.position.lng)],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        distanceResultCb,
      );
    }
  }

  function isChosenMaterials(obj: any): obj is ChosenMaterials {
    if (typeof obj !== 'object' || obj === null) return false;
    return true;
  }

  function distanceResultCb(response: any, status: any) {
    if (status === 'OK') {
      if (response.rows[0].elements[0].status === 'OK') {
        setEstimatedDistance({
          text: response.rows[0].elements[0].distance.text,
          meters: response.rows[0].elements[0].distance.value,
        });
        setEstimatedTime({
          text: response.rows[0].elements[0].duration.text,
          seconds: response.rows[0].elements[0].duration.value,
        });
      } else {
        setEstimatedDistance(undefined);
        setEstimatedTime(undefined);
      }
    }
  }

  if (!initDone) {
    return <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />;
  } else {
    return (
      <section id='add-job'>
        <header id='add-job__header'>
          <h2>{editMode ? 'Redigera uppdrag' : 'Lägg till nytt uppdrag'}</h2>
          {editMode && (
            <button
              onClick={confirmDelete}
              className='button--cancel'
              style={{ alignSelf: 'flex-end' }}
            >
              <span className='fa fa-trash' style={{ marginRight: '0.5rem' }} />
              Radera
            </button>
          )}
        </header>
        <main>
          <section id='add-job__form'>
            <ul id='add-job__form__ul'>
              {editMode && (
                <li key={`status-${status}`} className='add-job__form__li'>
                  <Select
                    isMultiSelect={false}
                    iconRightClose={<SelectIcon />}
                    iconRightOpen={<SelectIcon />}
                    onChange={onChangeStatus}
                    label='Status'
                    type='form'
                    initialOption={{
                      value: status.toString(),
                      label: jobStatusLabel[status],
                    }}
                  >
                    <>
                      {Object.entries(jobStatusLabel).map((s: [string, string]) => {
                        /**
                         * Should not show status payed when:
                         * 1. material has been taken out on Nolblad,
                         * 2. When client is central,
                         * 3. When vehicle is "lej"
                         */
                        if (
                          parseInt(s[0]) !== JobStatus.PAYED ||
                          !(vehicle.id === 'Lej' || job?.hasReceiptsForCompany)
                        ) {
                          return <Option key={s[0]} value={s[0]} label={s[1]} />;
                        } else {
                          return null;
                        }
                      })}
                    </>
                  </Select>
                </li>
              )}

              <li key={`job-type`} className='add-job__form__li'>
                <p className='label' style={{ alignItems: 'center', display: 'flex' }}>
                  Typ <InfoIcon style={{ marginLeft: '10px' }} />
                </p>
                <div style={{ marginBottom: '15px' }}></div>
                <Select
                  isMultiSelect={false}
                  searchBar={true}
                  iconRightClose={<SelectIcon />}
                  iconRightOpen={<SelectIcon />}
                  onChange={onChangeJobType}
                  label='Välj typ'
                  type='form'
                >
                  <>
                    {Object.entries(jobTypeLabel).map((s: [string, string]) => {
                      return <Option key={s[0]} value={s[0]} label={s[1]} />;
                    })}
                  </>
                </Select>
              </li>
              <div className={client ? 'extended-forms' : ''}>
                <AddClient />
                {!!client && (
                  <>
                    <AddContactClient />
                    <Divider />
                    <AddLittra />
                  </>
                )}
              </div>
              <div
                className={
                  [VehicleType.TRUCK, VehicleType.EXCAVATOR, VehicleType.MAN].includes(
                    vehicle.vehicleType,
                  )
                    ? 'extended-forms'
                    : ''
                }
              >
                <li key='vehicle' className='add-job__form__li'>
                  <p className='label'>Resurs</p>
                  <div style={{ marginBottom: '15px' }}></div>
                  <Select
                    isMultiSelect={false}
                    type='form'
                    label='Välj resurs'
                    searchBar={true}
                    onChange={onChangeVehicle}
                    iconRightClose={<SelectIcon />}
                    iconRightOpen={<SelectIcon />}
                  >
                    <>
                      {vehicles
                        .filter((v: VehicleData) => {
                          // filter away all trucks if jobtype is grävmaskin/anläggare.
                          return (
                            jobType !== JobType.EXCAVATOR_MAN ||
                            ![
                              VehicleType.TRUCK,
                              VehicleType.TRUCK_LEJ,
                              VehicleType.TRUCK_NOT_ASSIGNED,
                            ].includes(v.vehicleType)
                          );
                        })
                        .map((v: VehicleData) => {
                          return (
                            <Option
                              key={v.docId}
                              value={v.docId}
                              label={`${v.id} (${vehicleTypeLabel[v.vehicleType]})`}
                            />
                          );
                        })}
                    </>
                  </Select>
                </li>

                {/* FÖRARE DROP-DOWN LIST*/}
                {[VehicleType.TRUCK, VehicleType.EXCAVATOR, VehicleType.MAN].includes(
                  vehicle.vehicleType,
                ) && (
                  <>
                    <li key='driver' className='add-job__form__li'>
                      <p className='label'>Förare</p>
                      <div style={{ marginBottom: '15px' }} />
                      <Select
                        isMultiSelect={false}
                        type='form'
                        label='Förare'
                        onChange={onChangeDriver}
                        searchBar={true}
                        iconRightClose={<SelectIcon />}
                        iconRightOpen={<SelectIcon />}
                        initialOption={getInitialDriverOption()}
                      >
                        <>
                          {/* Admins are only allowed to pick drivers */}
                          {user?.userType == UserType.ADMIN &&
                            drivers.map((d: User) => {
                              return (
                                <Option
                                  key={d.docId}
                                  value={d.docId}
                                  label={`${d.firstName} ${d.lastName}`}
                                />
                              );
                            })}
                        </>
                      </Select>
                    </li>
                    <Divider />
                    <AddEquipments />
                  </>
                )}
              </div>

              <li key='from' id='add-job__form__date' className='add-job__form__li'>
                <div style={{ marginBottom: '15px', width: '45%' }}>
                  <p style={{ marginBottom: '15px' }} className='label'>
                    Dag till
                  </p>

                  <Datepicker
                    mode='day'
                    onChange={onChangeDateFrom}
                    currentDateMillis={start || new Date().getTime()}
                    showQuickChangeButtons={true}
                    buttonClassName='add-job__form__input add-job__form__date-date'
                    buttonContent={
                      <>
                        {' '}
                        <span className='fa fa-calendar' />
                        {`${start ? toDayDateString(start) : 'Välj start'}`}
                      </>
                    }
                  />
                </div>
                <div style={{ marginBottom: '15px', width: '45%' }}>
                  <p style={{ marginBottom: '15px' }} className='label'>
                    Dag från
                  </p>
                  <Datepicker
                    mode='day'
                    onChange={onChangeDateTo}
                    currentDateMillis={end || new Date().getTime()}
                    showQuickChangeButtons={true}
                    buttonClassName='add-job__form__input add-job__form__date-date'
                    buttonContent={
                      <>
                        <span className='fa fa-calendar' />
                        {`${end ? toDayDateString(end) : 'Välj slut'}`}
                      </>
                    }
                  />
                </div>
              </li>
              <div className='day-container'>
                <p style={{ marginBottom: '15px' }} className='label'>
                  Heldag
                </p>
                <Switch
                  style={{ width: '7%', marginBottom: '20px' }}
                  defaultChecked
                  size='small'
                  onClick={() => setShowHours(!showHours)}
                />
              </div>

              {showHours && (
                <li id='add-job__form__date' className='add-job__form__li'>
                  <div style={{ marginBottom: '15px', width: '45%' }}>
                    <p style={{ marginBottom: '15px' }} className='label'>
                      start tid
                    </p>
                    <Timepicker
                      onChangeHours={onChangeHoursFrom}
                      onChangeMinutes={onChangeMinutesFrom}
                      hours={start ? new Date(start).getHours() : 7}
                      minutes={start ? new Date(start).getMinutes() : 0}
                    />
                  </div>
                  <div style={{ marginBottom: '15px', width: '45%' }}>
                    <p style={{ marginBottom: '15px' }} className='label'>
                      slut tid
                    </p>

                    <Timepicker
                      onChangeHours={onChangeHoursTo}
                      onChangeMinutes={onChangeMinutesTo}
                      hours={end ? new Date(end).getHours() : 16}
                      minutes={end ? new Date(end).getMinutes() : 0}
                    />
                  </div>
                </li>
              )}
              <p className='label' style={{ alignItems: 'center', display: 'flex' }}>
                Bilder <InfoIcon style={{ marginLeft: '10px' }} />
              </p>
              <AddImages
                images={images}
                setImages={setImages}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
              />
              <div style={{ marginTop: '30px' }}></div>
              <p className='label'>Kommentar till Förare</p>
              <div style={{ marginBottom: '15px' }}></div>
              <li key='other' className='add-job__form__li'>
                <Textarea
                  id='other'
                  setState={setOtherInformation}
                  placeholder='Skriv en kommentar'
                  value={otherInformation}
                />
              </li>
              <p className='label'>Kommentar till kontoret</p>
              <div style={{ marginBottom: '15px' }}></div>
              <li key='admin-comments' className='add-job__form__li'>
                <Textarea
                  id='admin-comments'
                  setState={setAdminComments}
                  placeholder='Skriv en kommentar'
                  value={adminComments}
                />
              </li>
              {Array.from({ length: count }).map((_, index) =>
                routes && routes[index]?.index < count - 1 ? (
                  <div key={index}>
                    <div key={index}>
                      <JobDetailsDivider number={index + 1} />
                      <div
                        style={{
                          margin: '20px',
                          gap: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <p>{'Från: ' + routes[index]?.from?.name}</p>
                        <p>{'Till: ' + routes[index]?.to?.name}</p>
                        {isChosenMaterials(routes[index].materials) ? (
                          Object.keys(routes[index].materials).map((key) => (
                            <p key={key}>
                              Material:{' '}
                              {isChosenMaterials(routes[index].materials) &&
                                routes[index].materials[key].material.description}
                            </p>
                          ))
                        ) : (
                          <p>{'Godsslag: ' + routes[index].materials}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment key={index}>
                    <JobDetailsDivider number={index + 1} />
                    <div style={{ marginTop: '15px' }}></div>
                    <Radio.Group onChange={changeKorning} value={korning}>
                      <Space direction='vertical'>
                        <Radio value={1}>Material</Radio>
                        <Radio value={2}>Godsslag</Radio>
                      </Space>
                    </Radio.Group>
                    <div style={{ marginTop: '20px' }}></div>
                    <p className='label'>{korning === 1 ? 'Material' : 'Godsslag'}</p>
                    <div style={{ marginBottom: '20px' }}></div>
                    {jobType === JobType.TRANSPORT && korning === 1 && <AddMaterials />}
                    {(jobType === JobType.TRANSPORT || jobType === JobType.TRANSPORT_MACHINE) &&
                      korning === 2 && (
                        <>
                          <li key='other-material' className='add-job__form__li'>
                            <Textarea
                              id='other-material'
                              setState={setGodsslag}
                              placeholder='Godsslag'
                              value={godsslag}
                            />
                          </li>
                        </>
                      )}
                    {/* {jobType === JobType.EXCAVATOR_MAN ? (
                    <li key='place' className='add-job__form__li' id='add-job__form__li-place'>
                      <p className='label'>Arbetsplats</p>
                      <PickAPlace
                        place={from}
                        onChange={setFrom}
                        onSaveToLittra={onSavePlaceToLittra}
                        getSavedPlaces={!isLej}
                      />
                    </li>
                  ) : (
                    <li key='place' className='add-job__form__li' id='add-job__form__li-place'>
                      {!!estimatedTime && (
                        <div className='info'>
                          <p className='label'>Tidsuppskattning</p>
                          <p>{estimatedTime.text}</p>
                        </div>
                      )}

                      {!!estimatedDistance && (
                        <div className='info'>
                          <p className='label'>Avståndsuppskattning</p>
                          <p>{estimatedDistance.text}</p>
                        </div>
                      )}
                    </li>
                  )} */}
                    {[
                      VehicleType.TRUCK_LEJ,
                      VehicleType.EXCAVATOR_LEJ,
                      VehicleType.MAN_LEJ,
                    ].includes(vehicle.vehicleType) && (
                      <>
                        <AddSubcontractor />
                        {!!subcontractor && <AddContactSubcontractor />}
                      </>
                    )}
                    {/* <div>
                      <div>
                        <p className='label'>Resurs</p>
                        {vehicle?.id}
                      </div>
                      <div>
                        <p className='label'>Släp</p>
                        {vehicle?.equipments.map((e) => {
                          return e.serviceId;
                        })}
                      </div>
                      <div>
                        <p className='label'>Flak</p>
                      </div>
                    </div> */}
                    <div className='place-container'>
                      <div>
                        <p className='label'>Plats från</p>
                        <div style={{ marginBottom: '15px' }}></div>
                        <PickAPlace
                          place={from}
                          onChange={setFrom}
                          onSaveToLittra={onSavePlaceToLittra}
                          getSavedPlaces={!isLej}
                        />
                      </div>

                      <section id='add-job__form__swap-place'>
                        <button onClick={swapPlaces}>
                          <div className='swap-places'>
                            <VectorRight />
                            <VectorLeft />
                          </div>
                        </button>
                      </section>
                      <div>
                        <p className='label'>Plats till</p>
                        <div style={{ marginBottom: '15px' }}></div>

                        <PickAPlace
                          place={to}
                          onChange={setTo}
                          onSaveToLittra={onSavePlaceToLittra}
                          getSavedPlaces={!isLej}
                        />
                      </div>
                    </div>{' '}
                    <div style={{ marginBottom: '25px' }}></div>
                  </React.Fragment>
                ),
              )}
              <button
                id='button--add-contact'
                className='button-add-kontakt'
                onClick={() => addAndSaveRoute()}
              >
                <span className='fa fa-plus' />
                Lägg till fler körningar
              </button>
              {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
              <div className='save-container'>
                {' '}
                <button className='button--edit' onClick={openAddLittra} style={{ width: '50%' }}>
                  Spara littra som...
                </button>
                <button className='button--primary' onClick={save} style={{ width: '50%' }}>
                  Spara utkast
                </button>
              </div>
            </ul>
          </section>
        </main>
      </section>
    );
  }
}

export default AddJob;
