import { SyntheticEvent, useContext, useRef } from 'react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotificationStatus } from '../../utils/constants';

import './AddImages.css';
import JobDetail from '../../popups/job_details/JobDetail';
import { Image, Plus } from 'lucide-react';
import PopupButton from '../../popups/PopupButton';
import DragAndDrop from '../drag_and_drop/dragAndDrop';

interface AddImagesProps {
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  images: string[];
  deletedImages?: string[];
  setDeletedImages?: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function AddImages({
  setImages,
  images,
  deletedImages,
  setDeletedImages,
}: AddImagesProps) {
  const { notify } = useContext(NotificationContext);
  const imageInputRef = useRef<HTMLInputElement>(null);

  function clickOnImageInput() {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  }

  async function deleteImage(idx: number) {
    const _images = [...images];

    // if they want to store which images have been deleted (used in ReportJob where we need to know later which images to delete from storage)
    if (_images[idx].startsWith('https') && deletedImages && setDeletedImages) {
      setDeletedImages([...deletedImages, _images[idx]]);
    }
    _images.splice(idx, 1);

    setImages(_images);
  }

  function addImage(event: SyntheticEvent) {
    // save image in image array

    const files = (event.target as HTMLInputElement).files;

    // FileReader support
    if (FileReader && files && files.length) {
      const fr = new FileReader();

      fr.onload = function () {
        setImages([...images, fr.result as string]);
      };

      fr.readAsDataURL(files[0]);
    }

    // Not supported
    else {
      notify('Var snäll att uppgradera din webbläsare', NotificationStatus.ERROR);
    }
  }

  return (
    <JobDetail text1='' childrenOnNewLine={true}>
      <ul id='images__ul'>
        {images.map((image, idx) => {
          return (
            <li className='images__li' key={idx}>
              <img className='img' src={image} alt='' />
            </li>
          );
        })}
      </ul>

      <DragAndDrop
        text='Dra och släpp en fil eller ladda upp'
        icon={<Plus size={16} strokeWidth={3} />}
        onClick={clickOnImageInput}
      />
      <input
        id='add-images__input'
        type='file'
        accept='image/*'
        onChange={addImage}
        ref={imageInputRef}
      />
    </JobDetail>
  );
}
