import React, { useEffect, useState } from 'react';
import { JobStatus, JobType, vehicleNotAssigned } from '../../utils/constants';
import { getDefaultTimeFromMillis, getDefaultTimeToMillis } from '../../utils/time';
import {
  AddEditJobState,
  AddedMaterialData,
  ClientData,
  ContactData,
  DistanceEstimate,
  IRoute,
  JobData,
  LittraData,
  Place,
  SubcontractorData,
  TimeEstimate,
  User,
  VehicleData,
} from '../../utils/types';

const noOp = () => {
  console.log('this function is not implemented');
};

const initialState: AddEditJobState = {
  jobType: JobType.TRANSPORT,
  status: JobStatus.NEW,
  vehicle: vehicleNotAssigned,
  start: null,
  end: null,
  otherInformation: '',
  otherMaterials: '',
  adminComments: '',
  vehicleEquipments: new Set(),
  materials: {},
  images: [],
  littraWorkplaceTemp: '',
  routes: [],
  materialFrom: '',
  updateStateEditMode: noOp,
  setJobType: noOp,
  setStatus: noOp,
  setFrom: noOp,
  setTo: noOp,
  setEnd: noOp,
  setStart: noOp,
  setVehicle: noOp,
  setVehicleEquipments: noOp,
  setDriver: noOp,
  setClient: noOp,
  setContactClient: noOp,
  setContactClientTemp: noOp,
  setLittra: noOp,
  setLittraTemp: noOp,
  setLittraWorkplaceTemp: noOp,
  setSubcontractor: noOp,
  setContactSubcontractor: noOp,
  setMaterials: noOp,
  setOtherInformation: noOp,
  setOtherMaterials: noOp,
  setAdminComments: noOp,
  setEstimatedDistance: noOp,
  setEstimatedTime: noOp,
  setImages: noOp,
  updateRoutes: noOp,
  editRoutes: noOp,
  setMaterialFrom: noOp,
};

interface AddEditJobProviderProps {
  children: React.ReactElement;
}

function guard<T>(elem: T, fun: (elem: T) => void) {
  if (elem) fun(elem);
}

export const AddEditJobContext = React.createContext(initialState);

export function AddEditJobProvider({ children }: AddEditJobProviderProps) {
  const [initDone, setInitDone] = useState(false);
  const [jobType, setJobType] = useState<JobType>(JobType.TRANSPORT);
  const [status, setStatus] = useState<JobStatus>(JobStatus.DRAFT);
  const [from, setFrom] = useState<Place>();
  const [to, setTo] = useState<Place>();
  const [estimatedTime, setEstimatedTime] = useState<TimeEstimate>();
  const [estimatedDistance, setEstimatedDistance] = useState<DistanceEstimate>();
  const [start, setStart] = useState<number | null>(null);
  const [end, setEnd] = useState<number | null>(null);
  const [vehicle, setVehicle] = useState<VehicleData>(vehicleNotAssigned);
  const [vehicleEquipments, setVehicleEquipments] = useState<Set<string>>(new Set()); // array of doc ref ids
  const [driver, setDriver] = useState<User>();
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [materialFrom, setMaterialFrom] = useState('');

  // CLIENT
  const [client, setClient] = useState<ClientData>();
  const [contactClient, setContactClient] = useState<ContactData>();
  const [contactClientTemp, setContactClientTemp] = useState<ContactData[]>([]);
  const [littra, setLittra] = useState<LittraData>(); // doc ref id
  const [littraTemp, setLittraTemp] = useState<string>('');
  const [littraWorkplaceTemp, setLittraWorkplaceTemp] = useState('');

  // useEffect(() => {
  //   console.log('LittraTemp: ', littraTemp);
  //   console.log('WorkplaceTemp: ', littraWorkplaceTemp);
  // }, [littraTemp, littraWorkplaceTemp]);

  // SUBCONTRACTOR
  const [subcontractor, setSubcontractor] = useState<SubcontractorData>();
  const [contactSubcontractor, setContactSubcontractor] = useState<ContactData>();

  interface ChosenMaterials {
    [docId: string]: AddedMaterialData;
  }
  const [materials, setMaterials] = useState<ChosenMaterials>({});
  const [images, setImages] = useState<string[]>([]);
  const [otherInformation, setOtherInformation] = useState<string>('');
  const [otherMaterials, setOtherMaterials] = useState<string>('');
  const [adminComments, setAdminComments] = useState<string>('');

  useEffect(() => {
    if (client === undefined && initDone) {
      resetClientSpecificData();
    }
  }, [client]);

  function resetClientSpecificData() {
    setLittra(undefined);
    setContactClient(undefined);
    setContactClientTemp([]);
  }

  const updateRoutes = (newroutes: IRoute[]) => {
    setRoutes([...routes, ...newroutes]);
  };

  const editRoutes = (updatedRoute: IRoute) => {
    if (updatedRoute.index >= 0 && updatedRoute.index < routes.length) {
      routes[updatedRoute.index] = updatedRoute;
    } else {
      console.error('Index out of bounds');
    }
  };

  function updateStateEditMode(job: JobData) {
    guard(job.jobType, setJobType);
    // CLIENT
    if (job.client) {
      setClient(job.client); // will trigger change of contactClients and littra in the components for that

      if (job.contactClient) {
        setContactClient(job.contactClient);
      } else if (job.contactClientTemp) {
        setContactClientTemp([]);
      }

      // LITTRA

      if (job.littra) {
        setLittra(job.littra);
      } else if (job.littraTemp) {
        setLittraTemp(job.littraTemp);
        if (job.littraWorkplaceTemp) setLittraWorkplaceTemp(job.littraWorkplaceTemp);
      }
    }

    // SUBCONTRACTOR
    if (job.subcontractor) {
      setSubcontractor(job.subcontractor);
      if (job.contactSubcontractor) {
        setContactSubcontractor(job.contactSubcontractor);
      }
    }

    setAdminComments(job.adminComments);
    setStart(job.start);
    setEnd(job.end);
    setOtherInformation(job.otherInformation);
    setOtherMaterials(job.otherMaterials);
    job.driver && setDriver(job.driver);
    setFrom(job.from);
    setTo(job.to);
    job.vehicle && setVehicle(job.vehicle);

    if (job.vehicleEquipments && job.vehicleEquipments.length) {
      setVehicleEquipments(new Set(job.vehicleEquipments.map((e) => e.docId)));
    }

    if (job.images) {
      setImages(job.images);
    }

    const _chosenMaterials: ChosenMaterials = {};

    if (job.materials) {
      for (const m of job.materials) {
        _chosenMaterials[m.material.docId] = m;
      }
      setMaterials(_chosenMaterials);
    }

    setStatus(job.status);
    setInitDone(true);
  }

  return (
    <AddEditJobContext.Provider
      value={{
        jobType,
        setJobType,
        status,
        setStatus,
        from,
        setFrom,
        to,
        setTo,
        estimatedDistance,
        setEstimatedDistance,
        estimatedTime,
        setEstimatedTime,
        start,
        setStart,
        end,
        setEnd,
        vehicle,
        setVehicle,
        vehicleEquipments,
        setVehicleEquipments,
        driver,
        setDriver,
        client,
        setClient,
        contactClient,
        setContactClient,
        contactClientTemp,
        setContactClientTemp,
        littra,
        setLittra,
        littraTemp,
        setLittraTemp,
        subcontractor,
        setSubcontractor,
        contactSubcontractor,
        setContactSubcontractor,
        materials,
        setMaterials,
        images,
        setImages,
        otherInformation,
        setOtherInformation,
        otherMaterials,
        setOtherMaterials,
        adminComments,
        setAdminComments,
        updateStateEditMode,
        littraWorkplaceTemp,
        setLittraWorkplaceTemp,
        routes,
        updateRoutes,
        editRoutes,
        materialFrom,
        setMaterialFrom,
      }}
    >
      {children}
    </AddEditJobContext.Provider>
  );
}
