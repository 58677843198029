import { useContext } from 'react';
import { DataContext } from '../../contexts/CommonDataContext';
import { ClientData } from '../../utils/types';
import Select from '../select/Select';
import Option from '../select/Option';
import { ReactComponent as SelectIcon } from '../../images/selectIcon.svg';

interface AddClientProps {
  client?: ClientData;
  setClient: React.Dispatch<React.SetStateAction<ClientData | undefined>>;
}

/**
 * Component that is used in 'AddEditJob* to add/select client
 */
export default function AddClient({ client, setClient }: AddClientProps) {
  const { clients, clientsLoading } = useContext(DataContext);

  function onChangeClient(value: string) {
    const _client = clients.find((c) => c.docId === value);
    if (_client) {
      // update client
      setClient(_client);
    } else {
      setClient(undefined);
    }
  }

  return (
    <li key='client' className='add-job__form__li'>
      {clients && (
        <>
          <p className='label'>Kund</p>
          <div style={{ marginBottom: '15px' }}></div>
          <Select
            isMultiSelect={false}
            type='form'
            label='Välj eller börja skirva kundnamn'
            searchBar={true}
            onChange={onChangeClient}
            iconRightClose={<SelectIcon />}
            iconRightOpen={<SelectIcon />}
            initialOption={client ? { value: client.docId, label: client.name } : undefined}
          >
            <>
              {clientsLoading ? (
                <div className='option'>Laddar kunder...</div>
              ) : (
                clients.map((c: ClientData) => {
                  return <Option key={c.docId} value={c.docId} label={c.name} />;
                })
              )}
            </>
          </Select>
        </>
      )}
    </li>
  );
}
