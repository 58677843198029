import { View, Text } from '@react-pdf/renderer';
import { getHoursElapsed, toRangeDateString } from '../../utils/time';
import { ChosenMaterials, IRoute, JobData, JobReportData, ReportRowData } from '../../utils/types';
import styles from './PDFStyles';
import { JobType } from '../../utils/constants';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

type Props = {
  route?: IRoute;
  job: JobData;
  report?: JobReportData;
};

const PdfReport = ({ route, job, report }: Props) => {
  const getMaterialQty = (material: ChosenMaterials) => {
    const key = Object.keys(material)[0];
    const howMuch = material[key].howMuch;
    const qtyValues = howMuch.map((item) => item.qty);
    return qtyValues;
  };

  const materialFromInfo = (materialFrom: string | undefined) => {
    switch (materialFrom) {
      case 'Material uttaget på Nolblads':
        return 'Nolblads';
      case 'Material uttaget på kund':
        return 'Kund';
      default:
        return '-';
    }
  };

  function isChosenMaterials(obj: any): obj is ChosenMaterials {
    if (!obj || typeof obj !== 'string') return true;
    return false;
  }

  return (
    <View style={{ ...styles.tableColumn }} key={Math.random()} wrap={false}>
      {/* Table titles */}
      {/* Table body if no report rows */}
      {job.jobType === JobType.FACILITY && (
        <View style={{ ...styles.tableRow, width: '100%' }}>
          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}>
              {job.start && format(new Date(job.start), 'EEE d/M', { locale: sv })}
            </Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}>{job.vehicle?.serviceId}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '20%' }}>
            <Text style={styles.tableBody}>{job.vehicle?.service}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '10%' }}>
            <Text style={styles.tableBody}>{'antal'}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '10%' }}>
            <Text style={styles.tableBody}>{'enhet'}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '30%' }}>
            <Text style={styles.tableBody}>{report?.other}</Text>
          </View>
        </View>
      )}
      {job.jobType === JobType.TRANSPORT && (
        <View style={{ ...styles.tableRow, width: '100%' }}>
          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}>{job.vehicle?.serviceId}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}>{job.vehicle?.service}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '20%' }}>
            <Text style={styles.tableBody}>
              {route ? route.from?.name + ' - ' + route.to?.name : 'Ingen rutt'}
            </Text>
          </View>
          {isChosenMaterials(route?.materials) && (
            <View style={{ ...styles.tableCell, width: '10%' }}>
              <Text style={styles.tableBody}>{String(getMaterialQty(route?.materials)[0])}</Text>
            </View>
          )}

          {isChosenMaterials(route?.materials) && (
            <View style={{ ...styles.tableCell, width: '10%' }}>
              <Text style={styles.tableBody}>{getMaterialQty(route?.materials)[1]}</Text>
            </View>
          )}

          <View style={{ ...styles.tableCell, width: '30%' }}>
            <Text style={styles.tableBody}>{materialFromInfo(route?.materialFrom)}</Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default PdfReport;
