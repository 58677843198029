import { createContext, SyntheticEvent, useEffect, useState } from 'react';
import { stopPropagation } from '../../utils/uiHelpers';

import './Select.css';

interface SelectProps {
  disabled?: boolean;
  label?: string;
  isMultiSelect: boolean;
  values?: Set<string>; // if multiselect
  type: string; // form or button css
  children: React.ReactElement;
  searchBar?: boolean;
  onChange: (value: string, state?: boolean) => void;
  onChangeSearch?: (value: string) => void; // for places search
  iconLeftOpen?: string;
  iconLeftClose?: string;
  iconRightOpen: React.ReactNode;
  iconRightClose: React.ReactNode;
  showLabel?: boolean;
  initialOption?: { value: string; label: string };
  className?: string;
}

interface SelectState {
  search: string;
  values?: Set<string>; // if multi select
  updateState: (value: string, label: string, state: boolean) => void;
}

const initialState: SelectState = {
  search: '',
  values: new Set(),
  updateState: (value: string, label: string, state: boolean) => {
    console.log('updateState not implemented');
  },
};

export const SelectContext = createContext(initialState);

export default function Select({
  disabled,
  isMultiSelect,
  initialOption,
  values,
  type,
  children,
  onChange,
  onChangeSearch,
  searchBar,
  iconLeftOpen,
  iconLeftClose,
  iconRightOpen,
  iconRightClose,
  label,
  showLabel,
  className,
}: SelectProps) {
  const [singleLabel, setSingleLabel] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    if (initialOption) {
      setSingleLabel(initialOption.label);

      if (searchBar && !showLabel) {
        setSearch(initialOption.label);
      }
    } else {
      setSingleLabel('');
    }
  }, [initialOption]);

  function updateState(value: string, label: string, state: boolean) {
    if (!isMultiSelect) {
      setSingleLabel(label);
      setShowOptions(false);

      if (searchBar && !showLabel) {
        setSearch(label);
      }
    }

    onChange(value, state);
  }

  function toggleShowOptions(event: SyntheticEvent) {
    stopPropagation(event);
    event.preventDefault();
    setShowOptions(!showOptions);
  }

  function _onChangeSearch(event: SyntheticEvent) {
    const searchString = (event.target as HTMLInputElement).value;

    setSearch(searchString);
    if (onChangeSearch) {
      onChangeSearch(searchString);
    }

    if (searchString === '') {
      setSingleLabel(undefined);
      onChange('', false);
    }
  }

  function eraseOption() {
    if (search) {
      setSearch('');
      onChange('', false);
    } else {
      setSingleLabel(undefined);
      onChange('', false);
    }
  }

  function onKeyPress(event: SyntheticEvent) {
    if ((event as unknown as KeyboardEvent).key === 'Enter') {
      event.preventDefault();
      toggleShowOptions(event);
    } else {
      if (!showOptions) {
        setShowOptions(true);
      }
    }
  }

  return (
    <SelectContext.Provider
      value={isMultiSelect ? { values, updateState, search } : { updateState, search }}
    >
      {showOptions && (
        <div
          className='overlay-select'
          onClick={toggleShowOptions}
          onScroll={() => {
            console.log('ON SCROLL');
          }}
        />
      )}

      <section id='select'>
        {
          <div id='select__search'>
            <input
              id='select__search__input'
              className='input-text'
              type='text'
              value={search}
              placeholder={label}
              onChange={_onChangeSearch}
              onClick={toggleShowOptions}
              onKeyPress={onKeyPress}
              autoComplete='on'
            />
            {iconRightOpen && (
              <span style={{ marginTop: '1rem' }}>
                {showOptions ? iconRightOpen : iconRightClose}
              </span>
            )}
            {search && <button id='clear__button' onClick={eraseOption} className='fa fa-times' />}
          </div>
        }

        {showOptions && <ul id='select__options'>{children}</ul>}
      </section>
    </SelectContext.Provider>
  );
}
