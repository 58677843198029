import React, { SyntheticEvent, useContext, useState } from 'react';
import { JobDataPartial, SlingaData } from '../../../utils/types';
import {
  getJobStatusLabel,
  getPartialJobBackgroundColor,
  setCorrectJobStatusTextColor,
} from '../../../utils/styling';
import { PdfJobsContext } from '../../../contexts/PdfJobsContext';
import { stopPropagation } from '../../../utils/uiHelpers';
import { hasAdminPrivileges } from '../../../utils/otherHelpers';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLittraText, getJobHours } from '../../../utils/jobUtils';
import { AuthContext } from '../../../contexts/AuthContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { copySlinga } from '../../../firebase/firestore_functions/slinga';
import { copyJob } from '../../../firebase/firestore_functions/job';
import { NotificationStatus } from '../../../utils/constants';
import './WeekDayEntry.css';
import { Copy } from 'lucide-react';
import { JobsContext } from '../../../contexts/JobsContext';

interface props {
  dateMillis: number | string;
  job: JobDataPartial & SlingaData;
  onClickJob: (docId: string, clickedDate: number) => void;
  onClickSlinga: (slinga: string) => void;
}

export const WeekDayEntry = ({ dateMillis, job, onClickJob, onClickSlinga }: props) => {
  const { isIncludedInPdf, onPdfJobChange } = useContext(PdfJobsContext);
  const { setSelectedJobs } = useContext(JobsContext);
  const { user } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);
  const [loadingCopy, setLoadingCopy] = useState('');

  async function copy(job: JobDataPartial & SlingaData) {
    setLoadingCopy(job.docId);

    const response =
      job.sNumber !== undefined ? await copySlinga(job.docId) : await copyJob(job.docId);

    switch (response.code) {
      case 201:
        notify('Jobb kopierat', NotificationStatus.SUCCESS);
        break;
      case 404:
        notify('Jobbet som skulle kopieras kunde inte hittas', NotificationStatus.ERROR);
        break;
      case 500:
        notify('Jobbet kunde inte kopieras', NotificationStatus.ERROR);
        break;
    }

    setLoadingCopy('');
  }

  console.log(job);
  console.log('=====');

  const onJobClick = (job: JobDataPartial & SlingaData) => {
    setSelectedJobs(job.docId);
    onPdfJobChange(job.docId);
  };

  const orderNumber =
    job.sNumber !== undefined ? (
      <p>
        Slinga {job.sNumber}
        <br />
      </p>
    ) : (
      job.orderNum
    );

  const resource = job.name !== undefined ? job.name : job.vehicle?.name;

  return (
    <tr
      className='week-day-entry'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (job.sNumber !== undefined) {
          onClickSlinga(job.docId);
        } else if (job.slinga) {
          onClickSlinga(job.slinga.id);
        } else if (typeof dateMillis !== 'string') {
          onClickJob(job.docId, dateMillis);
        } else if (typeof dateMillis === 'string') {
          onClickJob(job.docId, new Date().getTime());
        }
      }}
    >
      <td style={{ width: '5%' }}>
        <input
          type='checkbox'
          checked={isIncludedInPdf(job.docId)}
          onChange={() => {
            onJobClick(job);
          }}
          onClick={stopPropagation}
        />
      </td>
      <td style={{ width: '15%', maxWidth: '15%' }}>{orderNumber}</td>
      <td style={{ width: '15%', maxWidth: '15%' }}>{resource}</td>
      {job.driver?.firstName === undefined ? (
        <td>Ingen förare</td>
      ) : (
        <td style={{ width: '10%', maxWidth: '10%' }}>{job.driver?.firstName}</td>
      )}
      <td style={{ width: '20%', maxWidth: '20%' }}>{job.client?.name}</td>
      <td style={{ width: '15%', maxWidth: '15%' }}>{getLittraText(job)}</td>
      {job.sNumber !== undefined ? (
        <td style={{ width: '10%', maxWidth: '10%' }}></td>
      ) : (
        <td style={{ width: '10%', maxWidth: '10%' }}>
          {typeof dateMillis !== 'string' && Math.round(getJobHours(job, dateMillis) * 100) / 100}
        </td>
      )}
      <td style={{ width: '10%', maxWidth: '10%' }}>
        <div
          className='status-box'
          style={{
            backgroundColor: getPartialJobBackgroundColor(job, user, false),
            color: setCorrectJobStatusTextColor(job),
          }}
        >
          {getJobStatusLabel(job)}
        </div>
      </td>
      <td>
        {hasAdminPrivileges(user) && (
          <button
            onClick={(event: SyntheticEvent) => {
              stopPropagation(event);

              copy(job);
            }}
            className={
              loadingCopy === job.docId
                ? 'value fa fa-circle-o-notch fa-spin button--icon'
                : undefined
            }
          >
            {loadingCopy === job.docId ? null : <Copy size={16} />}
          </button>
        )}

        {job.smsHasBeenSent ? (
          <FontAwesomeIcon icon={faBell} title={'SMS till förare har skickats'} />
        ) : (
          <FontAwesomeIcon
            icon={faBell}
            style={{ color: 'transparent' }}
            className='unfilled-icon'
          />
        )}
      </td>
    </tr>
  );

  /* return (
    <tr
      key={job.docId}
      tabIndex={0}
      onClick={() => {
        if (job.sNumber !== undefined) {
          onClickSlinga(job.docId);
        } else if (job.slinga) {
          onClickSlinga(job.slinga.id);
        } else {
          onClickJob(job.docId, dateMillis);
        }
      }}
      style={{ backgroundColor: getPartialJobBackgroundColor(job, user) }}
    >
      <td>
        <input
          type='checkbox'
          checked={isIncludedInPdf(job.docId)}
          onChange={() => {
            onPdfJobChange(job.docId);
          }}
          onClick={stopPropagation}
        />
        {(job.orderNum ? job.orderNum : '') + ' ' + job.vehicle?.name}
      </td>
      <td>{job.orderNum ? job.orderNum : ''}</td>
      <td>{job.driver?.firstName || '-'}</td>
      <td>
        {' '}
        {job.sNumber !== undefined && (
          <p
            style={{
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            Slinga{job.sNumber}
          </p>
        )}
        {job.client?.name}
      </td>
      <td>
        {' '}
        {job.sNumber !== undefined && (
          <p
            style={{
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            Slinga{job.sNumber}
          </p>
        )}
        {getLittraText(job)}
      </td>

      {job.sNumber !== undefined ? <td></td> : <td>{getJobHours(job, dateMillis) || '-'}</td>}

      <td>{getJobStatusLabel(job)}</td>

      <td>
        {hasAdminPrivileges(user) && (
          <button
            onClick={(event: SyntheticEvent) => {
              stopPropagation(event);

              copy(job);
            }}
            className={
              loadingCopy === job.docId
                ? 'value fa fa-circle-o-notch fa-spin button--icon'
                : 'value fa fa-copy button--icon'
            }
          />
        )}

        {job.smsHasBeenSent && (
          <FontAwesomeIcon
            title={'SMS till förare har skickats'}
            color={'hsl(155, 100%, 25%)'}
            icon={faEnvelope}
          />
        )}
      </td>
      <td></td>
    </tr>
  ); */
};
